import React, { useState, useLayoutEffect, useEffect } from 'react';
import { CButton, CFormSelect, CForm, CFormInput, CFormCheck, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/react'
import Swal from "sweetalert2"
import axios from 'axios'
import ReactLoading from 'react-loading';
import Select from 'react-select'
import './InsertPermiso.css'
import dayjs from 'dayjs';

function InsertPermiso() {
  let [loading, setLoading] = useState(false)
  let [loading2, setLoading2] = useState(true)
  let [alumnos, setAlumnos] = useState([])
  let [rutas, setRutas] = useState([])
  let [botonRuta, setBotonRuta] = useState('Selecciona una ruta')
  let [ruta, setRuta] = useState()
  let [alumno, setAlumno] = useState()
  let [alumnoID, setAlumnoID] = useState()
  let [deIda, setDeIda] = useState(false)
  let [deRegreso, setDeRegreso] = useState(false)
  let [direcciones, setDirecciones] = useState([])
  let [direccionDestino, setDireccionDestino] = useState('')
  let [comentario, setComentario] = useState('')
  let [fechas, setFechas] = useState([])
  let [tipoPermiso, setTipoPermiso] = useState('')
  let [recoge, setRecoge] = useState('')
  let [familiaID, setFamiliaID] = useState('')

  useLayoutEffect(() => {
    axios.get(`${process.env.REACT_APP_BackURL}/getallalumnos`)
      .then((res) => {
        let temp = []
        res.data.data.forEach((alumno) => { temp.push({ value: { alumno }, label: `${alumno.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())} / ${alumno.student_code}` }) })
        setAlumnos(temp); setLoading2(false)
      })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/getAllRutas`)
      .then((response) => { setRutas(response.data.data.sort((a, b) => a.numero - b.numero)); setLoading(false) })
      .catch((err) => { console.log(err) })
  }, [])

  function handleSubmit() {

    if (tipoPermiso == 'papaRecoge') {
      if (recoge == '') {
        Swal.fire('Escribe el nombre de la persona que recoge')
        return
      }
    }

    if (tipoPermiso == 'direccionTemporal') {
      if (direccionDestino == '') {
        Swal.fire('Selecciona una direccion destino')
        return
      } else if (deIda == false && deRegreso == false) {
        Swal.fire('Selecciona al menos un tipo de cambio')
        return
      }
      else if (ruta == undefined) {
        Swal.fire('Selecciona una ruta')
        return
      }
    }

    if (fechas.length == 0) {
      Swal.fire('Selecciona por lo menos un dia')
      return
    }

    // setLoading(true)

    let data

    if (tipoPermiso == 'papaRecoge') {
      data = {
        fechas: fechas,
        permiso: {
          tipo: "papaRecoge",
          familiaID: familiaID,
          alumnoInvitaID: alumno._id,
          alumnoInvitadoID: alumno._id,
          status: "Aceptado por escuela",
          personaRecoge: recoge
        }
      }

      axios.post(`${process.env.REACT_APP_BackURL}/insertPermisoPadresFromAdmin`, data)
        .then(res => {
          Swal.fire('Permiso creado con exito')
            .then(() => { window.location.href = "/permisos" })
        })
        .catch(err => console.log(err))
        .catch(err => console.log(err))
    }

    if (tipoPermiso == 'direccionTemporal') {

      data = {
        fechas: fechas,
        deIda: deIda,
        deRegreso: deRegreso,
        permiso: {
          tipo: "direccionTemporal",
          familiaID: familiaID,
          alumnoInvitadoID: alumno._id,
          status: "Aceptado por escuela",
          direccionDestino: direccionDestino.split('%')[1],
          idRutaDestino: ruta,
        }

      }

      axios.post(`${process.env.REACT_APP_BackURL}/insertPermisoTemporal`, data)
        .then((response) => {
          setLoading(false)
          Swal.fire('Permiso creado con exito')
            .then(() => { window.location.reload() })
            .catch((err) => { console.log(err) })
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
          Swal.fire('Error al crear el permiso')
            .then(() => { window.location.reload() })
            .catch((err) => { console.log(err) })
        })
    }
  }

  useEffect(() => {

    let direccionesSecundarias

    if (alumno) { direccionesSecundarias = alumno.padres.filter((v) => !v.papaOmama)[0].secondary_contacts }

    setDirecciones(direccionesSecundarias)

  }, [alumno])

  return (
    <>

      <br />

      <CForm>

        <center>

          <div className='col'>

            <h6>Escoge el tipo de permiso</h6>
            <CFormSelect className='col' style={{ "maxWidth": "20vw" }} onChange={(e) => { setTipoPermiso(e.target.value) }}>
              <option>Tipo de permiso</option>
              <option value="papaRecoge">Salida con padres</option>
              <option value="direccionTemporal">Direccion temporal</option>
            </CFormSelect>

            <br />

            <h6>Escoge el alumno para el permiso</h6>
            {
              !loading2 ?
                <Select placeholder="Selecciona..." className='col inputr' options={alumnos} onChange={(e) => { setFamiliaID(e.value.alumno.padres.filter((papa) => papa.papaOmama == undefined)[0]._id); setAlumno(e.value.alumno); setAlumnoID(e.value.alumno._id); setDireccionDestino(''); setDeIda(false); setDeRegreso(false); setRuta() }} />

                : <><div><ReactLoading type={'bubbles'} color={'#46eb34'} height={'2vw'} width={'2vw'} /></div></>
            }
          </div>

          <br />

          <h6>Escoge la(s) fecha(s)</h6>
          <CFormInput style={{ maxWidth: "20vw" }} type="date" className='col' onChange={(e) => {
            if (new Date(e.target.value).getDay() != 5 && !fechas.includes(e.target.value)) {
              setFechas((prev) => [...prev, e.target.value])

            }
          }} />

          {
            fechas.length == 0 ? null :
              <>
                <br />
                <h6>Fechas seleccionadas para el permiso</h6>
              </>
          }

          {
            fechas.map((fecha, i) => {
              return (
                <div key={i} className='row'>
                  <center>
                    <div className='col fechas'>
                      <span>{dayjs(fecha).format('DD/MM/YYYY')}  </span>
                      <span style={{ color: "red" }} onClick={() => setFechas(fechas.filter((item) => item != fecha))} color='danger' size='sm'>X</span>
                    </div>
                  </center>
                </div>
              )
            })
          }

          {
            tipoPermiso != 'direccionTemporal' ? null :
              <>
                <br />
                <h6>Escoge el tipo de cambio</h6>
                <CFormCheck checked={deIda} onChange={(e) => setDeIda(e.target.checked)} className='check' id="flexCheckDefault" label="De ida" />
                <CFormCheck checked={deRegreso} onChange={(e) => setDeRegreso(e.target.checked)} className='check' id="flexCheckDefault" label="De regreso" />

                <br />

                {
                  !direcciones != [] ? null :
                    <>
                      <h6>Escoge la direccion destino</h6>
                      <CFormSelect className='col' style={{ "maxWidth": "20vw" }} onChange={(e) => { setDireccionDestino(e.target.value) }}>
                        <option>Direcciones disponibles</option>
                        {
                          direcciones.map((direccion, i) => {
                            return (
                              <option key={i} value={`${direccion.contact_name}%${direccion.contact_address}`}>{direccion.contact_name} {direccion.contact_address}</option>
                            )
                          })
                        }
                      </CFormSelect>
                    </>
                }

                <h6>Asigna la ruta para el cambio</h6>
                <CDropdown className='p-1'>
                  <CDropdownToggle color={"success"}>{botonRuta}</CDropdownToggle>
                  <CDropdownMenu className='dropdown-menu'>
                    {
                      rutas.map((ruta, i) => (
                        <CDropdownItem key={i} onClick={() => { setRuta(ruta._id); setBotonRuta(`${ruta.nombre} ${ruta.direccionRuta}`) }}>{ruta.numero} {ruta.nombre} {ruta.direccionRuta}</CDropdownItem>
                      ))
                    }
                  </CDropdownMenu>
                </CDropdown>
              </>
          }

          {
            tipoPermiso != 'papaRecoge' ? null :
              <>
                <br />
                <h6>Persona que lo recoge</h6>
                <CFormInput placeholder='Nombre' style={{ "maxWidth": "20vw" }} className='col' type="text" onChange={(e) => { setRecoge(e.target.value) }} /> <br />
              </>
          }

          <br />

          {/* <h6>Escribe algun comentario extra</h6>
          <CFormInput placeholder='Comentario' style={{ "maxWidth": "400px" }} className='col' type="text" onChange={(e) => { setComentario(e.target.value) }} /> <br /> */}

        </center>

      </CForm>


      <div>
        {
          loading ?
            <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
            :
            alumno == undefined ? null
              :
              <>
                <center>
                  <CButton color="info" onClick={() => { handleSubmit() }}>Crear permiso</CButton>

                </center>
              </>
        }
      </div>

      <br /> <br />

    </>
  );
}

export default InsertPermiso
