import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import AuthProvider from "../contexts/AuthContext"
import PrivateRoute from './PrivateRoute'
import '../scss/style.scss'

import Login from '../components/Users/Login'
import Dashboard from '../components/Dashboard'
import Alumnos from '../components/Alumnos'
import Padres from '../components/Padres'
import Familias from '../components/Familias'
import Permisos from '../components/Permisos'
import Prefectas from '../components/Prefectas'
import Camiones from '../components/Camiones'
import Rutas from '../components/Rutas'
import Usuarios from '../components/Users/Usuarios'
import InsertRuta from '../components/InsertRuta'
import HistorialRutas from '../components/HistorialRutas'
import PapaRecoge from '../components/PapaRecoge'
import CambiosFijos from '../components/CambiosFijos'
import InsertCambioFijo from '../components/InsertCambioFijo'
import InsertPermiso from '../components/InsertPermiso'
import Excel from '../components/Excel'
import Reportes from '../components/Reportes'
import DatosActualizar from '../components/DatosActualizar'
import DiasHebreo from '../components/DiasHebreo'
import PermisosHoy from '../components/PermisosHoy'
import CambiosHoy from '../components/CambiosHoy'
import CancelaCambiosFijos from '../components/CancelaCambiosFijos'
import Imprimir from '../components/Imprimir'
import ImprimirLista from '../components/ImprimirLista'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

function Routes() {
  {
    return (
      <AuthProvider>
        <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/" component={Login} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/alumnos" component={Alumnos} />
              <PrivateRoute exact path="/padres" component={Padres} />
              <PrivateRoute exact path="/familias" component={Familias} />
              <PrivateRoute exact path="/permisos" component={(props) => <Permisos {...props} viaje={false} />} />
              <PrivateRoute exact path="/permisosViaje" component={(props) => <Permisos {...props} viaje={true} />} />
              <PrivateRoute exact path="/cambiosfijos" component={CambiosFijos} />
              <PrivateRoute exact path="/prefectas" component={Prefectas} />
              <PrivateRoute exact path="/camiones" component={Camiones} />
              <PrivateRoute exact path="/rutas" component={Rutas} />
              <PrivateRoute exact path="/historialrutas" component={HistorialRutas} />
              <PrivateRoute exact path="/insertRuta" component={InsertRuta} />
              <PrivateRoute exact path="/insertCambioFijo" component={InsertCambioFijo} />
              <PrivateRoute exact path="/insertPermiso" component={InsertPermiso} />
              <PrivateRoute exact path="/historialRutas" component={HistorialRutas} />
              <PrivateRoute exact path="/papaRecogeFijo" component={(props) => <PapaRecoge {...props} tipo={true} />} />
              <PrivateRoute exact path="/papaRecogeHoy" component={(props) => <PapaRecoge {...props} tipo={false} />} />
              <PrivateRoute exact path="/reportes" component={(props) => <Reportes {...props} archivo={false} />} />
              <PrivateRoute exact path="/archivoReportes" component={(props) => <Reportes {...props} archivo={true} />} />
              <PrivateRoute exact path="/datosactualizar" component={DatosActualizar} />
              <PrivateRoute exact path="/diashebreo" component={DiasHebreo} />
              <PrivateRoute exact path="/permisoshoy" component={PermisosHoy} />
              <PrivateRoute exact path="/cambioshoy" component={CambiosHoy} />
              <PrivateRoute exact path="/cancelacambiosFijos" component={CancelaCambiosFijos} />
              <PrivateRoute exact path="/usuarios" component={Usuarios} />
              <PrivateRoute exact path="/excel" component={Excel} />
              <Route exact path="/imprimir" component={Imprimir} />
              <Route exact path="/imprimirLista" component={ImprimirLista} />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </AuthProvider>
    )
  }
}

export default Routes
