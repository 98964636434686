import React from 'react'
import * as XLSX from 'xlsx'
import axios from 'axios'

function Excel() {


    function handleInputChange(event) {
        const target = event.target
        const name = target.name
        let hojas = []
        if (name === 'file') {
            let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            reader.onloadend = (e) => {
                var data = new Uint8Array(e.target.result);
                var workbook = XLSX.read(data, { type: 'array' });

                workbook.SheetNames.forEach(function (sheetName) {
                    // Here is your object
                    var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                    hojas.push({
                        data: XL_row_object,
                        sheetName
                    })
                })

                let filas = hojas[0].data

                let filas2 = filas.reduce((acc, item) => {
                    let found = acc.find((element) => element.numero === item.numero)
                    if (found) {
                        found.orden = `${found.orden} ${item.orden}`
                        found.student_code = `${found.student_code} ${item.student_code}`
                        found.horario = `${found.horario} ${item.horario}`
                    } else {
                        acc.push(item)
                    }
                    return acc
                }, [])

                filas2.forEach((fila) => {
                    fila.orden = fila.orden.split(' ')
                    fila.student_code = fila.student_code.split(' ')
                    fila.horario = fila.horario.split(' ')
                })

                filas2.forEach((fila) => {
                    fila.alumnos = []
                    fila.orden.forEach((orden, index) => {
                        fila.alumnos.push({
                            student_code: fila.student_code[index],
                            horario: fila.horario[index]
                        })
                    })
                    delete fila.orden
                    delete fila.student_code
                    delete fila.horario
                    delete fila.alumnoNombre
                    fila.nombre = fila.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())
                    fila.direccionRuta = !fila.direccionRuta ? null : fila.direccionRuta.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())
                })

                console.log(filas2)

                axios.post(`${process.env.REACT_APP_BackURL}/insertManyRutas`, filas2)
                    .then((res) => { console.log(res) })
                    .catch((err) => { console.log(err) })

            }
        }
    }
    return (
        <div>
            <input required type="file" name="file" onChange={handleInputChange} />

        </div>
    )
}

export default Excel