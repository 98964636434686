import React, { useState, useLayoutEffect, useEffect } from 'react'
import { CButton, CModal, CFormInput, CModalHeader, CModalTitle, CModalBody, CModalFooter, CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead, CDropdown, CDropdownToggle, CDropdownItem, CDropdownMenu } from '@coreui/react'
import { TableVirtuoso } from 'react-virtuoso';
import CIcon from '@coreui/icons-react'
import { cilAddressBook, cilArrowBottom, cilArrowRight, cilArrowTop, cilPencil, cilPlus, cilTrash } from '@coreui/icons';
import axios from 'axios'
import ReactLoading from 'react-loading';
import Select from 'react-select'
import Swal from "sweetalert2"
import exportFromJSON from "export-from-json";
import './Rutas.css'

function Rutas() {
  let [loading, setLoading] = useState(true)
  let [visible1, setVisible1] = useState(false)
  let [visible2, setVisible2] = useState(false)
  let [visible3, setVisible3] = useState(false)
  let [rutasOG, setRutasOG] = useState([])
  let [rutas, setRutas] = useState([])
  let [alumnos, setAlumnos] = useState([])
  let [alumnosSelect, setAlumnosSelect] = useState([])
  let [alumnoDisplay, setAlumnoDisplay] = useState()
  let [alumnosDisplay, setAlumnosDisplay] = useState([])
  let [nombreDisplay, setNombreDisplay] = useState("")
  let [alumnosSinRuta, setAlumnosSinRuta] = useState([])
  let [rutaEditar, setRutaEditar] = useState({ alumnos: [] })
  let [force, setForce] = useState(0)
  let [tituloSelect, setTituloSelect] = useState('Selecciona...')
  let [alumno, setAlumno] = useState('')
  let [horario, setHorario] = useState('')
  let [dias, setDias] = useState('entreSemana')
  const excludeColumns = ["_id", "is_active", "createdAt", "updatedAt"]; // excluye datos del arreglo donde se buscara

  function getRutas() {

    axios.get(`${process.env.REACT_APP_BackURL}/getAllAlumnos`)
      .then((response) => {

        axios.get(`${process.env.REACT_APP_BackURL}/getAllRutas`)
          .then((response2) => {

            axios.get(`${process.env.REACT_APP_BackURL}/alumnosSinRuta`)
              .then((response3) => {
                let temp = []
                response.data.data.forEach((alumno) => { temp.push({ value: { alumno }, label: `${alumno.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())} / ${alumno.student_code}` }) })
                setAlumnosSelect(temp);
                setAlumnos(response.data.data);
                setAlumnosSinRuta(response3.data.data)
                setRutas(response2.data.data.sort((a, b) => a.numero - b.numero))
                setRutasOG(response2.data.data.sort((a, b) => a.numero - b.numero));
                setLoading(false)
              })
          })
          .catch((err) => { console.log(err) })
          .catch((err) => { console.log(err) })
      })
      .catch((err) => { console.log(err) })
  }

  function exportarExcel() {

    let datos = []

    rutas.forEach((objeto) => {

      let resultado = objeto.alumnos.map((alumno) => ({
        'Numero de Ruta': objeto.numero,
        'Nombre de Ruta': objeto.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()),
        'Escuela': alumnos.find((alumno2) => alumno2._id == alumno.id).campus_name.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()),
        'Seccion': alumnos.find((alumno2) => alumno2._id == alumno.id).academic_section.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()),
        'Direccion de Ruta': objeto.direccionRuta == 'Ida' ? 'Entrada' : 'Salida',
        'Codigo de Alumno': alumnos.find((alumno2) => alumno2._id == alumno.id).student_code,
        'Nombre': alumnos.find((alumno2) => alumno2._id == alumno.id).nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()),
        'Direccion del Alumno': alumnos.find((alumno2) => alumno2._id == alumno.id).padres.filter((papa) => papa.papaOmama == undefined)[0].principal_address.split(',')[0].toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()),
        'Grado': alumnos.find((alumno2) => alumno2._id == alumno.id).degree.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()),
        'Horario': alumno.horario,
      }))
      datos = [...datos, ...resultado]
    })

    exportFromJSON({ data: datos, fileName: "Reporte_de_Rutas", exportType: "csv" })
  }

  // handle change event of search input
  const handleChange = (value) => {
    filterData(value)
  };

  const filterData = (value) => {

    let resultadoFiltrado = rutasOG.filter((ruta) => {
      return ruta.alumnos.some((alumno) => alumno.id == value)
    })

    setRutas(resultadoFiltrado)

  };

  function alumnoInfo(id) {
    let alumnoInfo = alumnos.find((alumno) => alumno._id == id)
    return alumnoInfo
  }

  function agregarAlumno(alumno) {

    if (alumno == '') { Swal.fire('Selecciona un alumno'); return }

    if (horario == '') { Swal.fire('Ingresa un horario'); return }

    if (rutaEditar.alumnos.some((alumnoRuta) => alumnoRuta.id == alumno.id)) { Swal.fire('Este alumno ya esta en la ruta'); return }

    let nuevoAlumno = { horario: horario, id: alumno._id }

    rutaEditar.alumnos = ([...rutaEditar.alumnos, nuevoAlumno])

    setAlumno('')
    setHorario('')
    setTituloSelect('Selecciona...')
  }

  function handleSubmit() {

    if (rutaEditar.nombre == '') { Swal.fire('Ingresa un nombre de ruta'); return }

    if (rutaEditar.numero == '') { Swal.fire('Ingresa un numero de ruta'); return }

    if (rutaEditar.direccionRuta == 'Selecciona') { Swal.fire('Selecciona una direccion de ruta'); return }

    if (rutaEditar.alumnos.length == 0) { Swal.fire('Agrega por lo menos alumno a la ruta'); return }

    setVisible3(false)
    setLoading(true)

    let data = {
      nombre: rutaEditar.nombre,
      numero: rutaEditar.numero,
      direccionRuta: rutaEditar.direccionRuta,
      alumnos: rutaEditar.alumnos,
      dias: rutaEditar.dias
    }

    axios.patch(`${process.env.REACT_APP_BackURL}/updateRuta?id=${rutaEditar._id}`, data)
      .then(() => {
        Swal.fire('Se edito la ruta con exito')
          .then(() => { window.location.href = "/rutas" })
        setLoading(false)
      })
      .catch((err) => { setLoading(false); Swal.fire('Ocurrio un error, intetenta de nuevo por favor.'); console.log(err) })
  }

  function borrarRuta(id) {

    Swal.fire({
      title: '¿Estas seguro?',
      text: "No podras revertir esta accion",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#46eb34',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, borrar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${process.env.REACT_APP_BackURL}/deleteRutaById?id=${id}`)

          .then(() => {
            Swal.fire(
              'Borrado',
              'La ruta ha sido borrada',
              'success'
            ).then(() => { window.location.href = "/rutas" })
          })
          .catch((err) => { console.log(err) })
      }

    })


  }

  function bajaAlumno(arr, index) {
    if (index < arr.length - 1) {
      [arr[index], arr[index + 1]] = [arr[index + 1], arr[index]];
    }
    return arr;
  }

  function subeAlumno(arr, index) {
    if (index > 0) {
      [arr[index], arr[index - 1]] = [arr[index - 1], arr[index]];
    }
    return arr;
  }

  useLayoutEffect(() => {
    getRutas()
  }, [])

  return (
    <>
      <div className='centro'>

        <CModal visible={visible1} size='lg'>

          <CModalHeader closeButton={false}>
            <CModalTitle>Alumnos en la ruta {nombreDisplay}</CModalTitle>
          </CModalHeader>

          <CModalBody>
            <CTable responsive color="dark" hover>

              <CTableHead>
                <CTableRow color='dark'>
                  <CTableHeaderCell>Horario</CTableHeaderCell>
                  <CTableHeaderCell>Codigo alumno</CTableHeaderCell>
                  <CTableHeaderCell>Alumno</CTableHeaderCell>
                  <CTableHeaderCell>Grado</CTableHeaderCell>
                  <CTableHeaderCell>Direcciones</CTableHeaderCell>
                </CTableRow>
              </CTableHead>

              <CTableBody>
                {
                  alumnosDisplay.length > 0 ?
                    alumnosDisplay.map((item, i) => {
                      return (
                        <CTableRow key={i} color="info">
                          <CTableDataCell>{item.horario}</CTableDataCell>
                          <CTableDataCell>{alumnoInfo(item.id).student_code}</CTableDataCell>
                          <CTableDataCell>{alumnoInfo(item.id).nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</CTableDataCell>
                          <CTableDataCell>{alumnoInfo(item.id).degree.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                          <CTableDataCell><CButton color='info' shape="rounded-pill" onClick={() => { setVisible2(true); setAlumnoDisplay(alumnoInfo(item.id)) }}><CIcon icon={cilAddressBook} size="xl" /></CButton></CTableDataCell>
                        </CTableRow>
                      )
                    })
                    : null
                }
              </CTableBody>

            </CTable>
          </CModalBody>

          <CModalFooter>
            <CButton color="secondary" onClick={() => setVisible1(false)}>Cerrar</CButton>
          </CModalFooter>
        </CModal>

        <CModal size='xl' visible={visible2}>

          <CModalHeader closeButton={false}>
            <CModalTitle>Direcciones de {!alumnoDisplay ? null : alumnoDisplay.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())} </CModalTitle>

          </CModalHeader>

          <CModalBody>

            <div className='row'>

              <div className='col'>
                <h4>Ida</h4>
                <CTable responsive color="dark" hover>

                  <CTableHead>
                    <CTableRow color='dark'>
                      <CTableHeaderCell>Dia</CTableHeaderCell>
                      <CTableHeaderCell>Destino</CTableHeaderCell>
                      <CTableHeaderCell>Parentesco</CTableHeaderCell>
                      <CTableHeaderCell>Direcciones</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>

                  <CTableBody>
                    <>
                      {
                        !alumnoDisplay ? null :
                          alumnoDisplay.direccionesFijasIda.map((item, i) => {
                            return (
                              <>
                                <CTableRow color="info">
                                  <CTableDataCell>{item.dia}</CTableDataCell>
                                  <CTableDataCell>{item.direccion == "papaRecoge" ? "" : item.direccion}</CTableDataCell>
                                  <CTableDataCell>{item.direccion == "Principal" ? alumnoDisplay.padres.filter((papa) => papa.papaOmama == "Papa").map(() => <CIcon icon={cilArrowRight} size="xxl" />) : item.direccion == "papaRecoge" ? <CIcon icon={cilArrowRight} size="xxl" /> : alumnoDisplay.padres.filter((papa) => papa.papaOmama == undefined)[0].secondary_contacts.filter((papa) => papa.contact_name == item.direccion).map((papa) => papa.relationship)}</CTableDataCell>
                                  <CTableDataCell>{item.direccion == "Principal" ? alumnoDisplay.padres.filter((papa) => papa.papaOmama == undefined)[0].principal_address.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : item.direccion == "papaRecoge" ? "Pasan por el" : alumnoDisplay.padres.filter((papa) => papa.papaOmama == undefined)[0].secondary_contacts.filter((papa) => papa.contact_name == item.direccion).map((papa) => papa.contact_address)}</CTableDataCell>
                                </CTableRow>
                              </>
                            )
                          })
                      }
                    </>
                  </CTableBody>

                </CTable>
              </div>

              <div className='col'>
                <h4>Regreso</h4>
                <CTable responsive color="dark" hover>

                  <CTableHead>
                    <CTableRow color='dark'>
                      <CTableHeaderCell>Dia</CTableHeaderCell>
                      <CTableHeaderCell>Destino</CTableHeaderCell>
                      <CTableHeaderCell>Parentesco</CTableHeaderCell>
                      <CTableHeaderCell>Direcciones</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>

                  <CTableBody>
                    <>
                      {
                        !alumnoDisplay ? null :
                          alumnoDisplay.direccionesFijasRegreso.map((item, i) => {
                            return (
                              <>
                                <CTableRow color="info">
                                  <CTableDataCell>{item.dia}</CTableDataCell>
                                  <CTableDataCell>{item.direccion == "papaRecoge" ? "" : item.direccion}</CTableDataCell>
                                  <CTableDataCell>{item.direccion == "Principal" ? alumnoDisplay.padres.filter((papa) => papa.papaOmama == "Papa").map(() => <CIcon icon={cilArrowRight} size="xxl" />) : item.direccion == "papaRecoge" ? <CIcon icon={cilArrowRight} size="xxl" /> : alumnoDisplay.padres.filter((papa) => papa.papaOmama == undefined)[0].secondary_contacts.filter((papa) => papa.contact_name == item.direccion).map((papa) => papa.relationship)}</CTableDataCell>
                                  <CTableDataCell>{item.direccion == "Principal" ? alumnoDisplay.padres.filter((papa) => papa.papaOmama == undefined)[0].principal_address.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : item.direccion == "papaRecoge" ? "Pasan por el" : alumnoDisplay.padres.filter((papa) => papa.papaOmama == undefined)[0].secondary_contacts.filter((papa) => papa.contact_name == item.direccion).map((papa) => papa.contact_address)}</CTableDataCell>
                                </CTableRow>
                              </>
                            )
                          })
                      }
                    </>
                  </CTableBody>

                </CTable>
              </div>

            </div>

          </CModalBody>

          <CModalFooter>
            <CButton color="secondary" onClick={() => setVisible2(false)}>Cerrar</CButton>
          </CModalFooter>
        </CModal>

        <CModal size='xl' visible={visible3}>

          <CModalHeader closeButton={false}>
            <CModalTitle>Editar Ruta</CModalTitle>
          </CModalHeader>

          <CModalBody>

            <div className='row'>

              <div className='col-5'>
                <CFormInput style={{ "width": "100%" }} type="text" id="floatingInput" label="Nombre" placeholder='Nombre' value={rutaEditar.nombre} onChange={(e) => { setRutaEditar({ ...rutaEditar, nombre: e.target.value }) }} />
              </div>

              <div className='col-3'>
                <CFormInput style={{ "width": "100%" }} type="text" id="floatingInput" label="Numero" placeholder='Numero' value={rutaEditar.numero} onChange={(e) => { setRutaEditar({ ...rutaEditar, numero: e.target.value }) }} />
              </div>

              <div className='col-2'>
                <h6>Direccion de ruta</h6>
                <CDropdown>
                  <CDropdownToggle color="secondary">{rutaEditar.direccionRuta}</CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem onClick={() => { rutaEditar.direccionRuta = "Ida"; setForce(force + 1) }}>Ida</CDropdownItem>
                    <CDropdownItem onClick={() => { rutaEditar.direccionRuta = "Regreso"; setForce(force + 1) }} >Regreso</CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </div>

              {
                !rutaEditar.direccionRuta == 'Ida' ? null :
                  <div className='col-2'>
                    <h6>Dias de la semana</h6>
                    <CDropdown>
                      <CDropdownToggle color="secondary">{rutaEditar.dias == 'entreSemana' ? "Lun a Vie" : "Domingo"}</CDropdownToggle>
                      <CDropdownMenu>
                        <CDropdownItem onClick={() => { rutaEditar.dias = "entreSemana"; setForce(force + 1) }}>Lun a Vie</CDropdownItem>
                        <CDropdownItem onClick={() => { rutaEditar.dias = "finSemana"; setForce(force + 1) }} >Domingo</CDropdownItem>
                      </CDropdownMenu>
                    </CDropdown>
                  </div>
              }

            </div>

            <br />

            <div className='row'>

              <div className='col-5'>

                <h6>Insertar alumnos en la ruta</h6>

                {
                  !loading ?
                    <Select placeholder={tituloSelect} className='input col' options={alumnosSelect} onChange={(e) => { setAlumno(e.value.alumno); setTituloSelect(e.value.alumno.nombre) }} />

                    : <><div><ReactLoading type={'bubbles'} color={'#46eb34'} height={'2vw'} width={'2vw'} /></div></>
                }

              </div>

              <div className='col-5'>

                <CFormInput className='input col' value={horario} label="Horario" placeholder='Horario' type="time" onChange={(e) => { setHorario(e.target.value) }} />

              </div>

              <div className='col-2'>

                <br />

                <CButton shape='rounded-pill' color="success" onClick={() => { agregarAlumno(alumno) }}><CIcon icon={cilPlus} size="xl" /></CButton>

              </div>

            </div>

            <br />

            {
              rutaEditar.alumnos.length == 0 ?

                <CTable style={{ backgroundColor: "rgb(82,93,113)" }} color="dark" hover>

                  <CTableHead>
                    <CTableRow color='info'>
                      <CTableHeaderCell className='cells'>No.</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Horario</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Nombre</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Codigo de Alumno</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Eliminar</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>

                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell colSpan="8" className='cells'>No hay alumnos en la ruta</CTableDataCell>
                    </CTableRow>
                  </CTableBody>

                </CTable>

                :

                <TableVirtuoso
                  style={{ height: "55vh", width: '100%', zIndex: 0 }}
                  data={rutaEditar.alumnos}
                  components={{
                    Table: ({ style, ...props }) => <CTable {...props} style={{ backgroundColor: "rgb(82,93,113)" }} color="dark" hover />,
                    TableHead: CTableHead,
                    TableRow: CTableRow,
                    TableBody: CTableBody
                  }}

                  fixedHeaderContent={() => (
                    <CTableRow color='info'>
                      <CTableHeaderCell className='cells'>No.</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Horario</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Nombre</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Codigo de Alumno</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Subir/Bajar</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Eliminar</CTableHeaderCell>
                    </CTableRow>
                  )}

                  itemContent={(index, item) => {
                    return (
                      <>
                        <CTableDataCell>{index + 1}</CTableDataCell>
                        <CTableDataCell className='cells'><input style={{ maxWidth: "70px" }} placeholder={rutaEditar.alumnos[index].horario} onChange={(e) => { rutaEditar.alumnos[index].horario = e.target.value }}></input></CTableDataCell>
                        <CTableDataCell className='cells'>{alumnoInfo(rutaEditar.alumnos[index].id).nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</CTableDataCell>
                        <CTableDataCell className='cells'>{alumnoInfo(rutaEditar.alumnos[index].id).student_code}</CTableDataCell>
                        <CTableDataCell className='cells'>
                          <CButton style={{ color: 'white' }} color="link" onClick={() => { rutaEditar.alumnos = subeAlumno(rutaEditar.alumnos, index); setForce(force + 1) }}><CIcon icon={cilArrowTop} size="xl" /></CButton>
                          <CButton style={{ color: 'white' }} color="link" onClick={() => { rutaEditar.alumnos = bajaAlumno(rutaEditar.alumnos, index); setForce(force + 1) }}><CIcon icon={cilArrowBottom} size="xl" /></CButton>
                        </CTableDataCell>
                        <CTableDataCell className='cells'><CButton color="danger" onClick={() => { rutaEditar.alumnos = rutaEditar.alumnos.filter((alumnoRuta) => alumnoRuta.id != item.id); setForce(force + 1) }}>Eliminar</CButton></CTableDataCell>
                      </>
                    )
                  }}
                />
            }

          </CModalBody>

          <CModalFooter>
            <CButton color="secondary" onClick={() => { setVisible3(false); setRutas(rutasOG) }}>Cancelar</CButton>
            <CButton color="info" onClick={() => { handleSubmit() }}>Guardar cambios</CButton>
          </CModalFooter>

        </CModal>

        <center>

          <div className='d-flex justify-content-center'>
            <CButton href='/insertRuta' color="success">Crear nuva ruta</CButton>

            {/* Este boton resetea las rutas de hoy
           <CButton style={{marginRight:"1vw"}} onClick={() =>
            axios.get(`${process.env.REACT_APP_BackURL}/generaRutasManual`)
              .then((response) => { console.log(response) })
              .catch((err) => { console.log(err) })
          } color="warning">Generar rutas de hoy Manual</CButton> */}

          </div>

          {/* <b  r /> */}

          {/* <CFormInput style={{ "width": "30%" }} type="text" id="floatingInput" label="Buscador" placeholder='Buscar' onChange={((e) => { handleChange(e.target.value) })} /> */}

        </center>

        <br />

        <center>
          {
            !loading ?
              <div className='botones'>
                <Select className='index' placeholder="Buscar alumnos" options={alumnosSelect} onChange={(e) => { filterData(e.value.alumno.id) }} />

                <CButton onClick={() => { setRutas(rutasOG) }} color="danger">Reset filtro</CButton>

                <CButton onClick={() => { exportarExcel() }} color="success">Exportar rutas a Excel</CButton>
              </div>

              : <><div><ReactLoading type={'bubbles'} color={'#46eb34'} height={'2vw'} width={'2vw'} /></div></>
          }
        </center>

        <br />

        <div className='botones'>


          <h5>{rutas.length} rutas registradas</h5>

          <h5>{alumnosSinRuta.length - 1 == -1 ? 0 : alumnosSinRuta.length - 1} alumnos sin ruta asignada</h5>

        </div>

        <br />

        <div className='row'>

          {
            !loading ?
              !rutas.length > 0 ?
                <center><h1>No hay resultados</h1></center>
                :
                <TableVirtuoso
                  style={{ height: "55vh", width: '50%', zIndex: "1" }}
                  data={rutas}
                  components={{
                    Table: ({ style, ...props }) => <CTable {...props} style={{ backgroundColor: "rgb(82,93,113)" }} color="dark" hover />,
                    TableHead: CTableHead,
                    TableRow: CTableRow,
                    TableBody: CTableBody
                  }}

                  fixedHeaderContent={() => (
                    <CTableRow color='info'>
                      <CTableHeaderCell className='cells'>Nombre</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Número</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Direccion</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Dia</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Alumnos</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Editar</CTableHeaderCell>
                      {/* <CTableHeaderCell className='cells'>Eliminar</CTableHeaderCell> */}
                    </CTableRow>
                  )}

                  itemContent={(index, item) => {
                    return (
                      <>
                        <CTableDataCell className='cells'>{item.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</CTableDataCell>
                        <CTableDataCell className='cells'>{item.numero}</CTableDataCell>
                        <CTableDataCell className='cells'>{item.direccionRuta}</CTableDataCell>
                        <CTableDataCell className='cells'>{item.dias == 'entreSemana' ? "Lun a Vie" : item.dias == 'finSemana' ? 'Domingo' : '---------'}</CTableDataCell>
                        <CTableDataCell className='cells'><CButton color='info' shape="rounded-pill" onClick={() => { setVisible1(true); setAlumnosDisplay(item.alumnos); setNombreDisplay(item.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())) }}><CIcon icon={cilAddressBook} size="xl" /></CButton></CTableDataCell>
                        <CTableDataCell className='cells'><CButton onClick={() => { setVisible3(true); setRutaEditar(item) }} color='warning' shape="rounded-pill"><CIcon icon={cilPencil} size="xl" /></CButton></CTableDataCell>
                        {/* <CTableDataCell className='cells'><CButton disabled onClick={() => { borrarRuta(item._id) }} color='danger' shape="rounded-pill"><CIcon icon={cilTrash} size="xl" /></CButton></CTableDataCell> */}
                      </>
                    )
                  }}
                />
              : <div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div>
          }

          {
            !loading ?
              !alumnosSinRuta.length > 0 ? null
                :
                <TableVirtuoso
                  style={{ height: "55vh", width: '50%', zIndex: "1" }}
                  data={alumnosSinRuta}
                  components={{
                    Table: ({ style, ...props }) => <CTable {...props} style={{ backgroundColor: "rgb(82,93,113)" }} color="dark" hover />,
                    TableHead: CTableHead,
                    TableRow: CTableRow,
                    TableBody: CTableBody
                  }}

                  fixedHeaderContent={() => (
                    <CTableRow color='info'>
                      <CTableHeaderCell className='cells'>Nombre</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>C.Familia</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>C.Alumno</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Grado</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Sección</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Escuela</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>S.Camion</CTableHeaderCell>
                    </CTableRow>
                  )}

                  itemContent={(index, item) => {
                    return (
                      <>
                        {item.student_code == "DEMO-01" ? null :
                          <>
                            <CTableDataCell className='cells'>{item.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</CTableDataCell>
                            <CTableDataCell className='cells'>{item.family_code}</CTableDataCell>
                            <CTableDataCell className='cells'>{item.student_code}</CTableDataCell>
                            <CTableDataCell style={{ fontSize: "0.8em" }} className='cells'>{item.degree.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                            <CTableDataCell className='cells'>{item.academic_section.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                            <CTableDataCell className='cells'>{item.campus_name.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                            <CTableDataCell className='cells'>{item.servicioCamion ? "Si" : "No"}</CTableDataCell>
                          </>
                        }
                      </>
                    )
                  }}
                />

              : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
          }

        </div>

      </div>
    </>
  )
}

export default Rutas
