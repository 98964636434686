import React, { useState, useLayoutEffect } from 'react'
import { CTable, CModal, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead, CModalBody, CModalHeader, CModalTitle, CButton, CModalFooter } from '@coreui/react'
import { TableVirtuoso } from 'react-virtuoso';
import ReactLoading from 'react-loading';
import exportFromJSON from "export-from-json";
import axios from 'axios'
import dayjs from 'dayjs';
import './DatosActualizar.css'

function DatosActualizar() {
  let [loading, setLoading] = useState(true)
  let [datos, setDatos] = useState([])
  let [show, setShow] = useState(false)
  let [display, setDisplay] = useState({})
  let [allFamilias, setAllFamilias] = useState([{}])

  function getDatos() {
    axios.get(`${process.env.REACT_APP_BackURL}/getAllDatosActualizar`)
      .then((response) => { setDatos(response.data.data.filter((item) => item.status)); setLoading(false) })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/getAllFamilias`)
      .then((response) => { setAllFamilias(response.data.data) })
      .catch((err) => { console.log(err) })
  }

  function getInfoFamily(family_code) {
    let info
    info = allFamilias.filter((item) => item.family_code == family_code)[0]
    return info
  }

  function aplicarCambios(id, i) {
    axios.patch(`${process.env.REACT_APP_BackURL}/updateDatosActualizar?id=${id}`, { status: false })
      .then(() => {
        setDatos(datos.filter((item) => item._id != id))
      })
      .catch((err) => { console.log(err) })
  }

  function exportXLSX() {
    let data = []

    datos.map((item) => {
      let obj = {
        Fecha: dayjs(item.fecha).format('DD/MM/YYYY'),
        Codigo_de_familia: item.datos[0].family_code,
        Informacion: item.datos.map((item) => `${item.campo} -> ${item.valorNuevo}`).join(' - ')
      }
      data.push(obj)
    })

    const fileName = "DatosActualizar";
    const exportType = "csv";
    exportFromJSON({ data, fileName, exportType })
  }

  useLayoutEffect(() => { getDatos() }, [])

  return (
    <>
      <CModal visible={show} onClose={setShow} size='lg'>
        <CModalHeader closeButton>
          <CModalTitle>Actualizar informacion de familia :  {display.datos ? `${display.datos[0].family_code} - ${getInfoFamily(display.datos[0].family_code).family_name.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}` : null}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <h4>Fecha: {dayjs(display.fecha).format('DD/MM/YYYY')} </h4>
          <br />
          {
            display.datos ?
              display.datos.map((item, index) => {
                return (
                  <div key={index}>
                    <h5>{`${item.campo} -> ${item.valorNuevo}`}</h5>
                    <br />
                  </div>
                )
              })
              : <></>
          }

          <br />

        </CModalBody>

        <CModalFooter>
          <CButton color='warning' onClick={() => { aplicarCambios(display._id) }}>Aplicado</CButton>
        </CModalFooter>

      </CModal>

      <div className='centro'>

        <div className='botones'>

          <h5>{datos.length} registros</h5>

          <CButton color='success' onClick={() => { exportXLSX() }}>Exportar a XLSX</CButton>

        </div>

        <br />

        {
          !loading ?
            datos.length == 0 ?
              <center><h1>No hay registros</h1></center>
              :
              <>
                <TableVirtuoso
                  style={{ height: "55vh", width: '100%' }}
                  data={datos}
                  components={{
                    Table: ({ style, ...props }) => <CTable {...props} style={{ backgroundColor: "rgb(82,93,113)" }} color="dark" hover />,
                    TableHead: CTableHead,
                    TableRow: CTableRow,
                    TableBody: CTableBody
                  }}

                  fixedHeaderContent={() => (
                    <CTableRow color='info'>
                      <CTableHeaderCell className='cells'>Fecha</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Codigo de familia</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Información</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Eliminar</CTableHeaderCell>
                    </CTableRow>
                  )}

                  itemContent={(index, item) => {
                    return (
                      <>
                        <CTableDataCell className='cells'>{dayjs(item.fecha).format('DD/MM/YYYY')}</CTableDataCell>
                        <CTableDataCell className='cells'>{item.datos[0].family_code}</CTableDataCell>
                        <CTableDataCell className='cells'><CButton color='info' shape="rounded-pill" onClick={() => { setShow(true), setDisplay(item) }}>Ver información</CButton></CTableDataCell>
                        <CTableDataCell className='cells'><CButton color='warning' onClick={() => { aplicarCambios(item._id, index) }}>Aplicado</CButton></CTableDataCell>
                      </>
                    )
                  }}
                />
              </>
            : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
        }

        <br />

      </div>
    </>
  )
}

export default DatosActualizar
