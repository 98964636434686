import React, { useContext, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CButton, CContainer, CHeader, CHeaderBrand, CHeaderNav, CHeaderToggler, CImage, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilMenu } from '@coreui/icons'
import Logo from '../assets/logo.jpg'
import { AuthContext } from "../contexts/AuthContext";
import { cilUser } from "@coreui/icons";
import { Form, Button, Modal } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import EditPassword from "../components/Users/EditPasword";
import "../index.css";

const AppHeader = () => {
  const { user1, logOut } = useContext(AuthContext);
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const [email, setEmail] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const editUser = () => {
    Swal.fire({ title: `Tu informacion sera editada`, icon: "warning", showCancelButton: true, allowEscapeKey: true, reverseButtons: true, confirmButtonColor: "#3085d6", cancelButtonColor: "#d33", cancelButtonText: "Cancelar", confirmButtonText: "Confirmar cambios" })

      .then((result) => {
        if (result.isConfirmed) {
          axios.patch(`${process.env.REACT_APP_BackURL}/updateUser/${user1.id}`, { email, }, { headers: { Authorization: `Bearer: ${localStorage.getItem("app_token")}` } })
            .then(() => {
              Swal.fire({ icon: "success", title: "Se edito con exito", confirmButtonText: `Ok`, timer: 2000, timerProgressBar: true, allowEscapeKey: true })

                .then(() => { window.location.reload() })
            })

            .catch((error) => {
              let message = error.response.data.message
              // console.log(error.response);
              Swal.fire({ icon: "error", title: "Oops...", text: "Lo sentimos esta acción no se pudo completar " + message, allowEscapeKey: true })
            })
        }
      });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Edita tu correo</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control placeholder={user1.email} onChange={(e) => setEmail(e.target.value)} type="email" />
            </Form.Group>
            <br />
            <EditPassword id={user1._id} />
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button type="submit" onClick={() => { handleClose() }} className="btn btn-danger">Cancelar</Button>
          <Button type="submit" onClick={() => { editUser() }} className="btn btn-info">Guardar</Button>
        </Modal.Footer>
      </Modal>

      <CHeader position="sticky" className="mb-4">
        <CContainer fluid>
          <CHeaderToggler onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}>
            <CIcon icon={cilMenu} size="lg" />
          </CHeaderToggler>

          <CHeaderBrand className="mx-auto d-md-none" to="/">
            <CImage className="sidebar-brand-full" src={Logo} height={100} />
          </CHeaderBrand>

          <CHeaderNav className="ms-3">
            <CDropdown variant="nav-item">
              <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
                <CButton color="light">
                  <CIcon icon={cilUser} size="xl" />
                </CButton>
              </CDropdownToggle>
              <CDropdownMenu className="pt-0" placement="bottom-end">
                {/* <CDropdownItem onClick={handleShow}><i className="far fa-edit"></i> Mi perfíl</CDropdownItem> */}
                <CDropdownItem onClick={() => { logOut() }}><i className="fa-solid fa-arrow-right-from-bracket"></i> Salír</CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </CHeaderNav>
        </CContainer>
      </CHeader>
    </>
  )
}

export default AppHeader