import React, { useState, useLayoutEffect, useEffect } from 'react';
import { CButton, CForm, CFormInput } from '@coreui/react'
import Swal from "sweetalert2"
import axios from 'axios'
import ReactLoading from 'react-loading';
import './InsertCamion.css'

function InsertCamion() {
  let [loading, setLoading] = useState(false)
  let [numero, setNumero] = useState('')
  let [placa, setPlaca] = useState('')
  // let [capacidad, setCapacidad] = useState('')

  function handleSubmit() {

    setLoading(true)

    axios.post(`${process.env.REACT_APP_BackURL}/insertCamion`, { numero, placa })
      .then((response) => {
        setLoading(false)
        Swal.fire('Camion creado con exito')
          .then(() => { window.location.reload() })
          .catch((err) => { console.log(err) })
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        Swal.fire('Error al crear el camion')
        .then(() => { window.location.reload() })
        .catch((err) => { console.log(err) })
      })
  }

  return (
    <>


      <CForm>

        <center>

          <h6>Numero de camión</h6>
          <CFormInput placeholder='Numero' style={{ "maxWidth": "200px" }} className='col' type="text" onChange={(e) => { setNumero(e.target.value) }} /> <br />

          <h6>ECO</h6>
          <CFormInput placeholder='ECO' style={{ "maxWidth": "200px" }} className='col' type="text" onChange={(e) => { setPlaca(e.target.value) }} /> <br />

          {/* <h6>Capacidad</h6>
          <CFormInput placeholder='Capacidad' style={{ "maxWidth": "200px" }} className='col' type="number" onChange={(e) => { setCapacidad(e.target.value) }} /> <br /> */}

        </center>

      </CForm>


      <div>
        {
          loading ?
            <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
            :
            <>
              <center>
                <CButton color="info" onClick={() => { handleSubmit() }}>Crear camion</CButton>
              </center>
            </>
        }
      </div>


    </>
  );
}

export default InsertCamion
