import React, { useEffect, useRef } from 'react';
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { fromLonLat } from 'ol/proj';
import { Style, Icon, Fill, Stroke, Circle, Text } from 'ol/style';
import Feature from 'ol/Feature';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import OSM from 'ol/source/OSM';
import { CButton } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilFullscreen } from '@coreui/icons';

const Mapa = (props) => {
  const mapRef = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (!mapRef.current) return;

    if (!map.current) {
      map.current = new Map({
        target: mapRef.current,
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
          new VectorLayer({
            source: new VectorSource(),
          }),
        ],
        view: new View({
          center: fromLonLat(props.ubicaciones.length > 0 ? props.ubicaciones[props.ubicaciones.length - 1].coordinates : [-99.19959469580294, 19.43628817722416]),
          zoom: 12,
        }),
      });
    }

    const vectorSource = map.current.getLayers().getArray()[1].getSource();

    vectorSource.clear();

    const stops = props.alumnosParadas;

    // aqui se agregan las paradas solo si la ruta ya esta finalizada
    if (!props.enRuta) {
      // Iterar sobre las coordenadas de las paradas para crear los elementos en el mapa
      stops.forEach((point, index) => {
        const feature = new Feature({
          geometry: new Point(fromLonLat(point.coordinates)),
        });

        feature.setStyle(
          new Style({
            image: new Circle({
              radius: 8,
              fill: new Fill({ color: '#f5f242' }),
              stroke: new Stroke({ color: '#fff', width: 2 }),
            }),
            text: new Text({
              text: point.name || '',
              offsetY: -16,
              fill: new Fill({ color: 'black' }),
              font: 'bold 12px sans-serif',
            }),
          })
        );

        // Agregar el elemento al origen de la capa de vector
        vectorSource.addFeature(feature);
      });

      const routeCoordinates = props.ubicaciones;

      routeCoordinates.forEach((point, index) => {
        const feature = new Feature({
          geometry: new Point(fromLonLat(point.coordinates)),
        });

        if (point.type === 'start' || point.type === 'end') {
          feature.setStyle(
            new Style({
              image: new Circle({
                radius: 10,
                fill: new Fill({ color: point.type === 'start' ? 'green' : 'red' }),
                stroke: new Stroke({ color: '#fff', width: 2 }),
              }),
              text: new Text({
                text: point.name,
                offsetY: -16,
                fill: new Fill({ color: 'black' }),
                font: 'bold 12px sans-serif',
              }),
            })
          );
        } else {
          feature.setStyle(
            new Style({
              text: new Text({
                text: point.name || '',
                offsetY: -16,
                fill: new Fill({ color: 'black' }),
                font: 'bold 12px sans-serif',
              }),
            })
          );
        }

        vectorSource.addFeature(feature);
      });

      const routeFeature = new Feature({
        geometry: new LineString(routeCoordinates.map(coord => fromLonLat(coord.coordinates))),
      });

      routeFeature.setStyle(
        new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
          stroke: new Stroke({
            color: '#0003a5',
            width: 10,
          }),
        })
      );

      vectorSource.addFeature(routeFeature);
    }

    // aqui se agrega el camion en vivo
    if (props.enRuta && props.live) {

      const liveFeature = new Feature({
        geometry: new Point(fromLonLat([props.live.data.coords.longitude, props.live.data.coords.latitude])),
      });

      liveFeature.setStyle(
        new Style({
          image: new Icon({
            src: 'https://res.cloudinary.com/activecode/image/upload/v1705083114/camionAzul.png',
            // anchor: [0.5, 1],
            // scale: 0.8,
          }),
          text: new Text({
            text: props.tituloRuta,
            offsetY: 30,
            fill: new Fill({ color: '#011a6e' }),
            font: 'bold 14px sans-serif',
          }),
        })
      );

      vectorSource.addFeature(liveFeature);
    }
  }, [props.alumnosParadas, props.ubicaciones, props.live, props.mostrarRuta, props.mostrarVehiculoEnVivo]);

  const fullscreenHandler = () => {
    if (!document.fullscreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
      if (mapRef.current.requestFullscreen) {
        mapRef.current.requestFullscreen();
      } else if (mapRef.current.webkitRequestFullscreen) {
        mapRef.current.webkitRequestFullscreen();
      } else if (mapRef.current.msRequestFullscreen) {
        mapRef.current.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <div ref={mapRef} style={{ width: '40vw', height: '30vh' }}></div>
      <CButton color='light' onClick={fullscreenHandler} style={{ position: 'absolute', top: '85%', left: '1%' }}>
        <CIcon icon={cilFullscreen} />
      </CButton>
    </div>
  )
};

export default Mapa;
