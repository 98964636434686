import React, { useState, useLayoutEffect } from 'react'
import { CButton, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead } from '@coreui/react'
import axios from 'axios'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactLoading from 'react-loading';
import './Rutas.css'
import dayjs from 'dayjs'
import { useHistory } from "react-router-dom";

function PermisosHoy() {
  let history = useHistory();
  let [loading, setLoading] = useState(true)
  let [permisosHoy, setPermisosHoy] = useState([])
  let [permisosHoyOG, setPermisosHoyOG] = useState([])
  let [selectedDate, setSelectedDate] = useState(new Date());
  let [tituloBoton1, setTituloBoton1] = useState("Filtrar por sección")

  function getPermisosHoy() {
    axios.get(`${process.env.REACT_APP_BackURL}/getPermisosByDate?dia=${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}&tipo=cambios`)
      .then((response) => { setPermisosHoy(response.data.data); setLoading(false); setPermisosHoyOG(response.data.data) })
      .catch((err) => { console.log(err) })
  }

  function exportarExcel() {
    let datos = []
    // let cantidad = alumnos.map((v) => v.cantidad)
    // let createdAt = alumnos.map((v) => v.createdAt)
    // let donador = alumnos.map((v) => v.donadorID.nombreCompleto)
    // let vendedor = alumnos.map((v) => v.abrejID.nombre)
    // let stripeOpaypal = alumnos.map((v) => v.stripeOpaypal)

    // for (var i = 0; i < cantidad.length; i++) {
    //   datos.push({
    //     'Cantidad': `$${cantidad[i]}`,
    //     'Desde': new Date(createdAt[i]).toLocaleString('es-MX', { month: 'short', year: 'numeric', day: '2-digit' }),
    //     'Donador': donador[i],
    //     'Vendedor': vendedor[i],
    //     'Stripe / PayPal': stripeOpaypal[i],
    //   });
    // }

    // exportFromJSON({ data: datos, fileName: "Reporte_Padres", exportType: "csv" })
  }

  function getPapaRecogeByDate(date) {

    let fecha = String(date)

    fecha = `${fecha.split('/')[0]}-${fecha.split('/')[1].toString().padStart(2, '0')}-${fecha.split('/')[2].toString().padStart(2, '0')}`

    setLoading(true)

    axios.get(`${process.env.REACT_APP_BackURL}/getPermisosByDate?dia=${fecha}`)
      .then((response) => { setPermisosHoy(response.data.data); setLoading(false) })
      .catch((err) => { console.log(err) })
  }

  const filterData = (value) => {
    if (value) { setPermisosHoy(permisosHoyOG.filter((item) => { return item.alumnoInvitadoID.academic_section == value })) }
  };

  useLayoutEffect(() => {
    getPermisosHoy()
  }, [])

  return (
    <>
      <div className='centro'>

        <center>

          {/* <CFormInput style={{ "width": "30%" }} type="text" id="floatingInput" label="Buscador" placeholder='Buscar' onChange={((e) => { handleChange(e.target.value) })} /> */}

          <br />

          <DatePicker dateFormat="dd/MM/yyyy" selected={selectedDate} onChange={(date) => { setSelectedDate(date); getPapaRecogeByDate(`${new Date(date).getFullYear()}/${new Date(date).getMonth() + 1}/${new Date(date).getDate()}`) }} />

        </center>

        <br />

        <div className='botones'>

          <CDropdown className='p-1'>

            <CDropdownToggle color="info">{tituloBoton1}</CDropdownToggle>

            <CDropdownMenu className='dropdown-menu'>

              <CDropdownItem onClick={() => { setPermisosHoy(permisosHoyOG); setTituloBoton1("Sección") }} style={{ color: "green" }}>Todos</CDropdownItem>

              {
                [...new Set(permisosHoyOG.map((item) => item.alumnoInvitadoID.academic_section))].map((seccion, i) => (

                  <CDropdownItem key={i} onClick={() => { filterData(seccion); setTituloBoton1(`${seccion.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}`) }} className='siHay' >{seccion.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CDropdownItem>
                ))
              }

            </CDropdownMenu>

          </CDropdown>

          <CButton onClick={() => history.push("/imprimir?tipo=direccionTemporal", { data: permisosHoy })}>Imprimir</CButton >
          <CButton onClick={() => history.push(`/imprimirLista?tipo=cambioFijo&fecha=${selectedDate}&filtro=${tituloBoton1}`, { data: permisosHoy })}>Imprimir lista</CButton >
          <h5>{permisosHoy.length > 0 ? `${permisosHoy.length} cambio${permisosHoy.length == 1 ? '' : 's'} autorizado${permisosHoy.length == 1 ? '' : 's'}` : 'Hoy no hay cambios para imprimir'}</h5>
        </div>

        <br />

        {
          !loading ?
            <>

              <CTable style={{ width: '80%', margin: 'auto' }} responsive color="dark" hover>
                <CTableHead>
                  <CTableRow color='info'>
                    <CTableHeaderCell>Fecha</CTableHeaderCell>
                    <CTableHeaderCell>Nombre</CTableHeaderCell>
                    <CTableHeaderCell>Grado</CTableHeaderCell>
                    <CTableHeaderCell>Sección</CTableHeaderCell>
                    <CTableHeaderCell>Escuela</CTableHeaderCell>
                    <CTableHeaderCell>En ruta</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>

                <CTableBody>
                  {
                    permisosHoy.length > 0 ?
                      <>
                        {
                          permisosHoy.map((item, i) => {
                            return (
                              <CTableRow key={i}>
                                <CTableDataCell>{dayjs(item.fecha).format('DD/MM/YYYY')}</CTableDataCell>
                                <CTableDataCell>{item.alumnoInvitadoID.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                                <CTableDataCell>{item.alumnoInvitadoID.degree.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())} {item.alumnoInvitadoID.group_code.split('-')[2]}</CTableDataCell>
                                <CTableDataCell>{item.alumnoInvitadoID.academic_section.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                                <CTableDataCell>{item.alumnoInvitadoID.campus_name.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                                <CTableDataCell>{item.idRutaDestino.numero} {item.idRutaDestino.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</CTableDataCell>
                              </CTableRow>
                            )
                          })
                        }
                      </>
                      :
                      <>
                        <CTableRow>
                          <CTableDataCell colSpan="8">Sin Resultados</CTableDataCell>
                        </CTableRow>
                      </>
                  }

                </CTableBody>
              </CTable>
            </>
            : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
        }

        <br />

      </div>
    </>
  )
}

export default PermisosHoy
