import React, { useState, useLayoutEffect } from 'react'
import { CFormInput, CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead, CModal, CModalBody, CModalHeader, CModalTitle, CButton, CModalFooter } from '@coreui/react'
import axios from 'axios'
import ReactLoading from 'react-loading';
import './Camiones.css'
import { TableVirtuoso } from 'react-virtuoso';
import CIcon from '@coreui/icons-react'
import { cilTrash } from '@coreui/icons';
import InsertCamion from './InsertCamion';

function Camiones() {
  let [loading, setLoading] = useState(true)
  let [camionesOG, setCamionesOG] = useState([])
  let [camiones, setCamiones] = useState([])
  let [visible, setVisible] = useState(false)

  const excludeColumns = ["_id", "is_active", "createdAt", "updatedAt"]; // excluye datos del arreglo donde se buscara

  function getPermisos() {
    axios.get(`${process.env.REACT_APP_BackURL}/getAllCamiones`)
      .then((response) => { setCamiones(response.data.data); setCamionesOG(response.data.data); setLoading(false) })
      .catch((err) => { console.log(err) })
  }

  // handle change event of search input
  const handleChange = (value) => {
    filterData(value)
  };

  // // filter records by search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setCamiones(camionesOG);
    else {
      const filteredData = camionesOG.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setCamiones(filteredData);
    }
  };

  useLayoutEffect(() => {
    getPermisos()
  }, [])

  return (
    <>

      <CModal visible={visible}>

        <CModalHeader closeButton={false}>
          <CModalTitle>Crear nuevo camión</CModalTitle>
        </CModalHeader>

        <CModalBody>
          <InsertCamion />
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>Cancelar</CButton>
        </CModalFooter>

      </CModal>

      <div className='centro'>

        <div className='botones'>
          <CButton onClick={() => setVisible(true)} color="success">Nuevo camion</CButton>
        </div>

        <br />

        <center>
          <CFormInput style={{ "width": "30%" }} type="text" id="floatingInput" label="Buscador" placeholder='Buscar' onChange={((e) => { handleChange(e.target.value) })} />
        </center>

        <br />

        <div className='botones'>

          <h5>{camiones.length} camiones registrados</h5>

        </div>

        <br />

        {
          !loading ?
            camiones.length == 0 ?
              <center><h1>No hay camiones registrados</h1></center>
              :
              <>
                <TableVirtuoso
                  style={{ height: "55vh", width: '100%' }}
                  data={camiones}
                  components={{
                    Table: ({ style, ...props }) => <CTable {...props} style={{ backgroundColor: "rgb(82,93,113)" }} color="dark" hover />,
                    TableHead: CTableHead,
                    TableRow: CTableRow,
                    TableBody: CTableBody
                  }}

                  fixedHeaderContent={() => (
                    <CTableRow color='info'>
                      {/* <CTableHeaderCell className='cells'>Status</CTableHeaderCell> */}
                      <CTableHeaderCell className='cells'>Numero</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Placa</CTableHeaderCell>
                      {/* <CTableHeaderCell className='cells'>Capacidad</CTableHeaderCell> */}
                      <CTableHeaderCell className='cells'>Eliminar</CTableHeaderCell>
                    </CTableRow>
                  )}

                  itemContent={(index, item) => {
                    return (
                      <>
                        {/* <CTableDataCell className='cells'>{item.status}</CTableDataCell> */}
                        <CTableDataCell className='cells'>{item.numero}</CTableDataCell>
                        <CTableDataCell className='cells'>{item.placa}</CTableDataCell>
                        {/* <CTableDataCell className='cells'>{item.capacidad}</CTableDataCell> */}
                        <CTableDataCell className='cells'><CButton color='danger' onClick={() => { axios.delete(`${process.env.REACT_APP_BackURL}/deleteCamionById?id=${item._id}`).then(() => { window.location.reload() }).catch((err) => { console.log(err) }) }}><CIcon icon={cilTrash} size="xl" /></CButton></CTableDataCell>
                      </>
                    )
                  }}
                />
              </>
            : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
        }

        <br />

      </div>
    </>
  )
}

export default Camiones
