import React, { useState, useLayoutEffect } from 'react'
import { CButton, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead } from '@coreui/react'
import axios from 'axios'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactLoading from 'react-loading';
import './Rutas.css'
import dayjs from 'dayjs'
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';

function PapaRecoge(props) {
  let history = useHistory();
  let [loading, setLoading] = useState(true)
  let [papaRecogeOG, setPapaRecogeOG] = useState([])
  let [papaRecoge, setPapaRecoge] = useState([])
  let [selectedDate, setSelectedDate] = useState(new Date());
  let [tituloBoton1, setTituloBoton1] = useState("Filtrar por sección")
  // let excludeColumns = ["_id", "is_active", "createdAt", "updatedAt"]; // excluye datos del arreglo donde se buscara

  function getPapaRecoge() {
    axios.get(`${process.env.REACT_APP_BackURL}/getPapaRecogeByDate?dia=${new Date().getFullYear()}/${new Date().getMonth() + 1}/${new Date().getDate()}`)
      .then((response) => {

        let data

        if (props.tipo) {
          data = response.data.data.filter((item) => { return item.fijo })
        } else {
          data = response.data.data.filter((item) => { return !item.fijo })
        }

        // esta funcion filtra los datos para que no se repitan, porque cuando los quito de las rutas, por cada ruta que lo quito se guarda en la base de datos que van a pasar por el, entonces se repiten
        let ids = []

        let sinRepetir = data.filter((item) => {
          let duplicados = ids.includes(item.alumnoInvitadoID._id);

          if (!duplicados) {
            ids.push(item.alumnoInvitadoID._id);
            return true;
          }
          return false;
        });

        setPapaRecoge(sinRepetir)
        setPapaRecogeOG(sinRepetir)
        setLoading(false)

      })
      .catch((err) => { console.log(err) })
  }

  // function exportarExcel() {
  //   let datos = []
  // let cantidad = alumnos.map((v) => v.cantidad)
  // let createdAt = alumnos.map((v) => v.createdAt)
  // let donador = alumnos.map((v) => v.donadorID.nombreCompleto)
  // let vendedor = alumnos.map((v) => v.abrejID.nombre)
  // let stripeOpaypal = alumnos.map((v) => v.stripeOpaypal)

  // for (var i = 0; i < cantidad.length; i++) {
  //   datos.push({
  //     'Cantidad': `$${cantidad[i]}`,
  //     'Desde': new Date(createdAt[i]).toLocaleString('es-MX', { month: 'short', year: 'numeric', day: '2-digit' }),
  //     'Donador': donador[i],
  //     'Vendedor': vendedor[i],
  //     'Stripe / PayPal': stripeOpaypal[i],
  //   });
  // }

  // exportFromJSON({ data: datos, fileName: "Reporte_Padres", exportType: "csv" })
  // }

  // handle change event of search input
  // const handleChange = (value) => {
  //   filterData(value)
  // };

  // // filter records by search text
  // const filterData = (value) => {
  //   const lowercasedValue = value.toLowerCase().trim();
  //   if (lowercasedValue === "") setPapaRecoge(papaRecogeOG);
  //   else {
  //     const filteredData = papaRecogeOG.filter((item) => {
  //       return Object.keys(item).some((key) =>
  //         excludeColumns.includes(key)
  //           ? false
  //           : item[key].toString().toLowerCase().includes(lowercasedValue)
  //       );
  //     });
  //     setPapaRecoge(filteredData);
  //   }
  // };

  function getPapaRecogeByDate(date) {

    setLoading(true)

    axios.get(`${process.env.REACT_APP_BackURL}/getPapaRecogeByDate?dia=${date}`)
      .then((response) => {

        // esta funcion filtra los datos para que no se repitan, porque cuando los quito de las rutas, por cada ruta que lo quito se guarda en la base de datos que van a pasar por el, entonces se repiten
        let ids = []

        let sinRepetir = response.data.data.filter((item) => {
          let duplicados = ids.includes(item.alumnoInvitadoID._id);

          if (!duplicados) {
            ids.push(item.alumnoInvitadoID._id);
            return true;
          }
          return false;
        });

        setPapaRecoge(sinRepetir)
        setPapaRecogeOG(sinRepetir)
        setLoading(false)
      })
      .catch((err) => { console.log(err) })

  }

  const filterData = (value) => {
    if (value) { setPapaRecoge(papaRecogeOG.filter((item) => { return item.alumnoInvitadoID.academic_section == value })) }
  };

  useLayoutEffect(() => {
    getPapaRecoge()
  }, [])

  return (
    <>
      <div className='centro'>

        <center>

          {/* <CFormInput style={{ "width": "30%" }} type="text" id="floatingInput" label="Buscador" placeholder='Buscar' onChange={((e) => { handleChange(e.target.value) })} /> */}

          <br />

          <DatePicker dateFormat="dd/MM/yyyy" selected={selectedDate} onChange={(date) => { setSelectedDate(date); getPapaRecogeByDate(`${new Date(date).getFullYear()}/${new Date(date).getMonth() + 1}/${new Date(date).getDate()}`) }} />

        </center>

        <br />

        <div className='botones'>

          <CDropdown className='p-1'>

            <CDropdownToggle color="info">{tituloBoton1}</CDropdownToggle>

            <CDropdownMenu className='dropdown-menu'>

              <CDropdownItem onClick={() => { setPapaRecoge(papaRecogeOG); setTituloBoton1("Sección") }} style={{ color: "green" }}>Todos</CDropdownItem>

              {
                [...new Set(papaRecogeOG.map((item) => item.alumnoInvitadoID.academic_section))].map((seccion, i) => (

                  <CDropdownItem key={i} onClick={() => { filterData(seccion); setTituloBoton1(`${seccion.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}`) }} className='siHay' >{seccion.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CDropdownItem>
                ))
              }

            </CDropdownMenu>

          </CDropdown>

          <CButton onClick={() => {

            if (papaRecoge.length === 0) return Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'No hay papelitos para imprimir',
            })

            history.push("/imprimir?tipo=papaRecoge", { data: papaRecoge })

          }}>Imprimir</CButton >

          <CButton onClick={() => history.push(`/imprimirLista?tipo=papaRecoge&fecha=${selectedDate}&filtro=${tituloBoton1}`, { data: papaRecoge })}>Imprimir lista</CButton >

          <h5>{papaRecoge.length > 0 ? `Hoy recogen a ${papaRecoge.length} ${papaRecoge.length > 1 ? "alumnos" : "alumno"}` : "Hoy no recogen alumnos en la escuela"}</h5>

        </div>

        <br />

        {
          !loading ?
            <>

              <CTable style={{ width: '80%', margin: 'auto' }} responsive color="dark" hover>
                <CTableHead>
                  <CTableRow color='info'>
                    <CTableHeaderCell>Fecha</CTableHeaderCell>
                    <CTableHeaderCell>Nombre</CTableHeaderCell>
                    <CTableHeaderCell>Codigo Alumno</CTableHeaderCell>
                    <CTableHeaderCell>Grado</CTableHeaderCell>
                    <CTableHeaderCell>Sección</CTableHeaderCell>
                    <CTableHeaderCell>Escuela</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>

                <CTableBody>
                  {
                    papaRecoge.length > 0 ?
                      <>
                        {
                          papaRecoge.map((item, i) => {
                            return (
                              <CTableRow key={i}>
                                <CTableDataCell>{dayjs(item.fecha).format('DD/MM/YYYY')}</CTableDataCell>
                                <CTableDataCell>{item.alumnoInvitadoID.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                                <CTableDataCell>{item.alumnoInvitadoID.student_code}</CTableDataCell>
                                <CTableDataCell>{item.alumnoInvitadoID.degree.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())} {item.alumnoInvitadoID.group_code.split('-')[2]}</CTableDataCell>
                                <CTableDataCell>{item.alumnoInvitadoID.academic_section.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                                <CTableDataCell>{item.alumnoInvitadoID.campus_name.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                              </CTableRow>
                            )
                          })
                        }
                      </>
                      :
                      <>
                        <CTableRow>
                          <CTableDataCell colSpan="8">Sin Resultados</CTableDataCell>
                        </CTableRow>
                      </>
                  }

                </CTableBody>
              </CTable>
            </>
            : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
        }

        <br />

      </div>
    </>
  )
}

export default PapaRecoge
