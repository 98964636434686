import React, { useState, useLayoutEffect, useEffect } from 'react'
import { CButton, CFormInput, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CModal, CModalBody, CModalHeader, CModalFooter, CModalTitle, CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead } from '@coreui/react'
import { TableVirtuoso } from 'react-virtuoso';
import axios from 'axios'
import dayjs from 'dayjs'
import ReactLoading from 'react-loading';
import Swal from 'sweetalert2';
import InsertPermiso from './InsertPermiso';
import Select from 'react-select'
import './Permisos.css'

function Permisos(props) {
  let [loading, setLoading] = useState(true)
  let [permisosOG, setPermisosOG] = useState([])
  let [permisos, setPermisos] = useState([])
  let [rutas, setRutas] = useState([])
  let [ruta, setRuta] = useState(null)
  // let [comentario, setComentario] = useState('')
  let [botonRuta, setBotonRuta] = useState('Ruta')
  let [descripcionRuta, setDescripcionRuta] = useState('')
  let [filtroAcumulado, setFiltroAcumulado] = useState({})
  let [tituloBoton1, setTituloBoton1] = useState("Status")
  let [tituloBoton2, setTituloBoton2] = useState("Fecha")
  let [tituloBoton3, setTituloBoton3] = useState("Tipo")
  let [searchText, setSearchText] = useState("")
  let [visible, setVisible] = useState(false)
  const excludeColumns = ["_id", "is_active", "createdAt", "updatedAt"]; // excluye datos del arreglo donde se buscara
  let [alumnosSelect, setAlumnosSelect] = useState([])
  let [filtroAlumnosOn, setFiltroAlumnosOn] = useState(false)
  let [filtros, setFiltros] = useState(false)

  function getPermisos() {
    axios.get(`${process.env.REACT_APP_BackURL}/getAllPermisos`)
      .then((response) => { setPermisos(response.data.data.sort((a, b) => a.fecha.localeCompare(b.fecha)).filter((permiso) => dayjs(permiso.fecha).isAfter(dayjs().subtract(1, 'day'))).filter((permiso) => permiso.permisoViaje == props.viaje)); setPermisosOG(response.data.data.sort((a, b) => a.fecha.localeCompare(b.fecha)).filter((permiso) => dayjs(permiso.fecha).isAfter(dayjs().subtract(1, 'day'))).filter((permiso) => permiso.permisoViaje == props.viaje)); setLoading(false) })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/getAllRutas`)
      .then((response) => { setRutas(response.data.data.sort((a, b) => a.numero - b.numero)); })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/getAllAlumnos`)
      .then((response) => {
        let temp = []
        response.data.data.forEach((alumno) => { temp.push({ value: { alumno }, label: `${alumno.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())} / ${alumno.student_code}` }) })
        setAlumnosSelect(temp);
      })
      .catch((err) => { console.log(err) })
  }

  const filterData = (value, filAcumulado) => {

    let temp = []

    let resultadoFiltrado = permisosOG.filter((item) => {

      for (let key in filAcumulado) {
        if (item[key] === undefined || item[key] != filAcumulado[key])
          return false;
      }
      return true;
    });

    temp = resultadoFiltrado

    const lowercasedValue = value ? value.toLowerCase().trim() : "";

    if (lowercasedValue === "") { setPermisos(temp) }
    else {
      const filteredData = temp.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setPermisos(filteredData);
    }
  };

  function cambiarStatus(id, status, tipo) {
    axios.patch(`${process.env.REACT_APP_BackURL}/updatePermiso?id=${id}&tipo=${tipo}`, { status: status, idRutaDestino: ruta }) //cambia el status del permiso
      .then((res) => {
        let permisosTemp = [...permisos]
        let permisosOGTemp = [...permisosOG]

        let index = permisosTemp.findIndex((permiso) => permiso._id == res.data.data._id)

        permisosTemp[index].status = res.data.data.status
        permisosOGTemp[index].status = res.data.data.status
        if (botonRuta != 'Ruta' && descripcionRuta != '') permisosTemp[index].idRutaDestino = { numero: botonRuta, nombre: descripcionRuta }

        setPermisos(permisosTemp)
        setPermisosOG(permisosOGTemp)
      })
      .catch((err) => { console.log(err) })
  }

  function filtrarAcumulado(key, valor) {
    setFiltroAcumulado({ ...filtroAcumulado, [key]: valor })
  }

  function eliminarFiltroAcumulado(key) {
    let filtroAcumuladoTemp = { ...filtroAcumulado }
    delete filtroAcumuladoTemp[key]
    setFiltroAcumulado(filtroAcumuladoTemp)
  }

  function existe(key, value) {
    let siHay = false

    permisos.forEach((alumno) => {
      if (alumno[key] == value) { siHay = true }
    })

    return siHay
  }

  function filtroActivo(obj, key) {
    return key in obj
  }

  function cruzaRutas() {
    axios.post(`${process.env.REACT_APP_BackURL}/revisaPermisosManual`, { ida: false, regreso: true })
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Permisos aplicados',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch((err) => { console.log(err) })
  }

  function filtrarPorAlumno(alumno) {
    let temp = permisosOG.filter((permiso) => permiso.alumnoInvitadoID._id == alumno)
    setPermisos(temp)
  }

  useLayoutEffect(() => {
    getPermisos()
  }, [])

  useEffect(() => {
    filterData(searchText, filtroAcumulado)
  }, [searchText, filtroAcumulado])

  return (
    <>

      <CModal visible={visible}>

        <CModalHeader closeButton={false}>
          <CModalTitle>Crear nuevo permiso</CModalTitle>
        </CModalHeader>

        <CModalBody style={{ backgroundColor: "rgb(235,237,239" }}>
          <InsertPermiso />
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>Cancelar</CButton>
        </CModalFooter>

      </CModal>

      <div className='botones'>

        <CButton onClick={() => cruzaRutas()} color="info">Aplicar permisos</CButton>

        <CButton onClick={() => setVisible(true)} color="success">Nuevo permiso</CButton>

      </div>

      <br />

      <div className='centro'>

        {/* <center>
          <CFormInput style={{ "width": "30%" }} type="text" id="floatingInput" label="Buscador" placeholder='Buscar' onChange={((e) => { setSearchText(e.target.value) })} />
        </center>

        <br /> */}

        <div className='botones'>

          {
            filtros ? null :
              !loading ?
                <Select className='buscaAlumnos' placeholder="Buscar alumnos" options={alumnosSelect} onChange={(e) => { filtrarPorAlumno(e.value.alumno.id); setFiltroAlumnosOn(true) }} />

                : <><div><ReactLoading type={'bubbles'} color={'#46eb34'} height={'2vw'} width={'2vw'} /></div></>
          }

          {
            filtroAlumnosOn ? null :
              <>

                <CDropdown className='p-1'>

                  <CDropdownToggle color={filtroActivo(filtroAcumulado, "fecha") ? "info" : "secondary"}>{tituloBoton2}</CDropdownToggle>

                  <CDropdownMenu className='dropdown-menu'>

                    {
                      !filtroActivo(filtroAcumulado, "fecha") ? null :
                        <CDropdownItem onClick={() => { eliminarFiltroAcumulado("fecha"); setTituloBoton2("Fecha") }} style={{ color: "red" }}>Eliminar filtro</CDropdownItem>
                    }

                    {
                      [...new Set(permisosOG.map((item) => item.fecha))].map((fecha, i) => (

                        <CDropdownItem key={i} onClick={() => { filtrarAcumulado("fecha", fecha); setTituloBoton2(`${fecha}`); setFiltros(true) }} className={!existe("fecha", fecha) ? "noHay" : "siHay"}>{fecha}</CDropdownItem>
                      ))
                    }

                  </CDropdownMenu>

                </CDropdown>

                <CDropdown className='p-1'>

                  <CDropdownToggle color={filtroActivo(filtroAcumulado, "tipo") ? "info" : "secondary"}>{tituloBoton3}</CDropdownToggle>

                  <CDropdownMenu className='dropdown-menu'>

                    {
                      !filtroActivo(filtroAcumulado, "tipo") ? null :
                        <CDropdownItem onClick={() => { eliminarFiltroAcumulado("tipo"); setTituloBoton3("Tipo") }} style={{ color: "red" }}>Eliminar filtro</CDropdownItem>
                    }

                    {
                      [...new Set(permisosOG.map((item) => item.tipo))].map((tipo, i) => (

                        <CDropdownItem key={i} onClick={() => { filtrarAcumulado("tipo", tipo); setTituloBoton3(`Tipo ${tipo}`); setFiltros(true) }} className={!existe("tipo", tipo) ? "noHay" : "siHay"}>{tipo}</CDropdownItem>
                      ))
                    }

                  </CDropdownMenu>

                </CDropdown>

                <CDropdown className='p-1'>

                  <CDropdownToggle color={filtroActivo(filtroAcumulado, "status") ? "info" : "secondary"}>{tituloBoton1}</CDropdownToggle>

                  <CDropdownMenu className='dropdown-menu'>

                    {
                      !filtroActivo(filtroAcumulado, "status") ? null :
                        <CDropdownItem onClick={() => { eliminarFiltroAcumulado("status"); setTituloBoton1("Status") }} style={{ color: "red" }}>Eliminar filtro</CDropdownItem>
                    }
                    {
                      [...new Set(permisosOG.map((item) => item.status))].map((status, i) => (

                        <CDropdownItem key={i} onClick={() => { filtrarAcumulado("status", status); setTituloBoton1(`Status ${status}`); setFiltros(true) }} className={!existe("status", status) ? "noHay" : "siHay"}>{status}</CDropdownItem>
                      ))
                    }

                  </CDropdownMenu>

                </CDropdown>
              </>
          }

          <CButton color='danger' onClick={() => { setFiltroAcumulado({}), setPermisos(permisosOG); setFiltroAlumnosOn(false); setFiltros(false) }}>Reset filtros</CButton>

          <h5>{permisos.length} permisos registrados</h5>

        </div>

        <br />

        {
          !loading ?
            permisos.length == 0 ?
              <center><h1>No hay permisos registrados</h1></center>
              :
              <>
                <TableVirtuoso
                  style={{ height: "55vh", width: '100%' }}
                  data={permisos}
                  components={{
                    Table: ({ style, ...props }) => <CTable {...props} style={{ backgroundColor: "rgb(82,93,113)" }} color="dark" hover />,
                    TableHead: CTableHead,
                    TableRow: CTableRow,
                    TableBody: CTableBody
                  }}

                  fixedHeaderContent={() => (
                    <CTableRow color='info'>
                      <CTableHeaderCell className='cellsPermisosMediano'>Fecha / Tipo / Status</CTableHeaderCell>
                      <CTableHeaderCell className='cellsPermisosMediano'>Alumno / Grado / Código</CTableHeaderCell>
                      <CTableHeaderCell className='cellsPermisos'>A casa de:</CTableHeaderCell>
                      <CTableHeaderCell className='cellsPermisosMediano'>Ida / Regreso</CTableHeaderCell>
                      <CTableHeaderCell className='cellsPermisosMediano'>Direccion Destino</CTableHeaderCell>
                      <CTableHeaderCell className='cellsPermisos'>Recoge</CTableHeaderCell>
                      <CTableHeaderCell className='cellsPermisos'>Ruta destino</CTableHeaderCell>
                      <CTableHeaderCell className='cellsPermisos'>Autorizar</CTableHeaderCell>
                    </CTableRow>
                  )}

                  itemContent={(index, item, i) => {
                    return (
                      <>
                        <CTableDataCell color={item.status == "En espera de escuela" ? "light" : item.status == "Rechazado por escuela" ? 'danger' : 'dark'} className='cellsPermisosMediano'>
                          <React.Fragment>
                            {dayjs(item.fecha).format('DD/MM/YYYY')}
                            <br />
                            {item.tipo}
                            {item.permisoViaje ?
                              <>
                                <br />
                                <span style={{ color: "red" }}>Solicitud por viaje</span >
                              </>
                              : null}
                            <br />
                            {item.status}
                          </React.Fragment>
                        </CTableDataCell>
                        <CTableDataCell color={item.status == "En espera de escuela" ? "light" : item.status == "Rechazado por escuela" ? 'danger' : 'dark'} className='cellsPermisosMediano'>
                          <React.Fragment>
                            {item.alumnoInvitadoID.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}
                            <br />
                            {item.alumnoInvitadoID.degree.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())} {item.alumnoInvitadoID.group_code.split('-')[2]}
                            <br />
                            {item.alumnoInvitadoID.student_code}
                            <br />
                            <div className={item.alumnoInvitadoID.campus_name == "TECAMACHALCO" || item.alumnoInvitadoID.campus_name == "INTERLOMAS" ? 'campus' : null} >
                              {item.alumnoInvitadoID.campus_name.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}
                            </div>
                          </React.Fragment>
                        </CTableDataCell>
                        <CTableDataCell color={item.status == "En espera de escuela" ? "light" : item.status == "Rechazado por escuela" ? 'danger' : 'dark'} className='cellsPermisos'>{item.alumnoInvitaID ? item.alumnoInvitaID.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : "No aplica"}
                        </CTableDataCell>
                        <CTableDataCell style={item.deIda ? { color: "red", fontWeight: "bold", textDecorationLine: "underline" } : null} color={item.status == "En espera de escuela" ? "light" : item.status == "Rechazado por escuela" ? 'danger' : 'dark'} className='cellsPermisosMediano'>{item.deIda ? "Ida" : "Regreso"}</CTableDataCell>
                        <CTableDataCell color={item.status == "En espera de escuela" ? "light" : item.status == "Rechazado por escuela" ? 'danger' : 'dark'} className='cellsPermisos'>{
                          item.tipo == "papaRecoge" ? "No aplica" :
                            item.tipo == "direccionTemporal" ? item.direccionDestino :
                              item.tipo == "casaAmigo" ?
                                item.alumnoInvitaID.direccionesFijasRegreso[dayjs(item.fecha).get('day')].direccion == "Principal" ?
                                  item.alumnoInvitaID.padres.filter((papa) => papa.papaOmama == undefined)[0].principal_address.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) :
                                  item.alumnoInvitaID.direccionesFijasRegreso[dayjs(item.fecha).get('day')].direccion == "papaRecoge" ? "Pasan por el" : item.alumnoInvitaID.padres.filter((papa) => papa.papaOmama == undefined)[0].secondary_contacts.filter((papa) => papa.contact_name == item.alumnoInvitaID.direccionesFijasRegreso[dayjs(item.fecha).get('day')].direccion).map((papa) => papa.contact_address.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()))
                                : "No aplica"}
                        </CTableDataCell>
                        <CTableDataCell color={item.status == "En espera de escuela" ? "light" : item.status == "Rechazado por escuela" ? 'danger' : 'dark'} className='cellsPermisos'>{item.tipo == "papaRecoge" ? item.personaRecoge : "No aplica"}
                        </CTableDataCell>
                        <CTableDataCell color={item.status == "En espera de escuela" ? "light" : item.status == "Rechazado por escuela" ? 'danger' : 'dark'} className='cellsPermisos'>
                          {
                            <>
                              {item.tipo == "papaRecoge" ? "No aplica" :
                                item.tipo == "casaAmigo" ?
                                  item.status == "Aceptado por escuela" ? `Ruta ${item.idRutaDestino.numero} ${item.idRutaDestino.nombre}` :
                                    item.alumnoInvitaID.direccionesFijasRegreso[dayjs(item.fecha).get('day')].direccion == "papaRecoge" ? "No aplica" :
                                      <CDropdown className='p-1' direction='dropstart'>
                                        <CDropdownToggle color={"success"}>{botonRuta}</CDropdownToggle>
                                        <CDropdownMenu className='dropdown-menu'>
                                          {
                                            rutas.map((ruta, i) => (
                                              <CDropdownItem key={i} onClick={() => { setRuta(ruta._id); setBotonRuta(`Ruta ${ruta.numero}`); setDescripcionRuta(ruta.nombre) }}>{ruta.numero} {ruta.nombre} {ruta.direccionRuta}</CDropdownItem>
                                            ))
                                          }
                                        </CDropdownMenu>
                                      </CDropdown> :
                                  item.status == "Aceptado por escuela" ? `Ruta ${item.idRutaDestino.numero} ${item.idRutaDestino.nombre}` :
                                    item.status == "Rechazado por escuela" || item.status == "En espera de escuela" ?
                                      <CDropdown className='p-1' direction='dropstart'>
                                        <CDropdownToggle color={"success"}>{botonRuta}</CDropdownToggle>
                                        <CDropdownMenu className='dropdown-menu'>
                                          {
                                            rutas.map((ruta, i) => (
                                              <CDropdownItem key={i} onClick={() => { setRuta(ruta._id); setBotonRuta(`Ruta ${ruta.numero}`); setDescripcionRuta(ruta.nombre) }}>{ruta.numero} {ruta.nombre} {ruta.direccionRuta}</CDropdownItem>
                                            ))
                                          }
                                        </CDropdownMenu>
                                      </CDropdown>
                                      : "En espera de padres"

                              }
                            </>
                          }
                        </CTableDataCell>
                        <CTableDataCell color={item.status == "En espera de escuela" ? "light" : item.status == "Rechazado por escuela" ? 'danger' : 'dark'} className='cellsPermisos'>
                          {
                            item.status == "En espera de padres" ? "En espera de padres" :
                              item.tipo == "papaRecoge" ?
                                <>
                                  <CButton disabled={item.status == "Aceptado por escuela" ? true : false} color="success" onClick={() => { cambiarStatus(item._id, 'Aceptado por escuela', item.tipo) }}><i className="fa-solid fa-check"></i></CButton>
                                  <CButton style={{ marginLeft: "5px" }} disabled={item.status == "Rechazado por escuela" ? true : false} color="danger" onClick={() => { cambiarStatus(item._id, 'Rechazado por escuela', item.tipo) }}><i className="fa-solid fa-xmark"></i></CButton>
                                </>
                                : item.tipo == "direccionTemporal" ?
                                  ruta ?
                                    <>
                                      <CButton disabled={false} color="success" onClick={() => { cambiarStatus(item._id, 'Aceptado por escuela', item.tipo, i) }}><i className="fa-solid fa-check"></i></CButton>
                                      <CButton style={{ marginLeft: "5px" }} disabled={item.status == "Rechazado por escuela" ? true : false} color="danger" onClick={() => { cambiarStatus(item._id, 'Rechazado por escuela', item.tipo) }}><i className="fa-solid fa-xmark"></i></CButton>
                                    </>
                                    :
                                    <>
                                      <CButton disabled={true} color="success" onClick={() => { cambiarStatus(item._id, 'Aceptado por escuela', item.tipo, i) }}><i className="fa-solid fa-check"></i></CButton>
                                      <CButton style={{ marginLeft: "5px" }} disabled={item.status == "Rechazado por escuela" ? true : item.status == "En espera de escuela" || item.status == "Aceptado por escuela" ? false : true} color="danger" onClick={() => { cambiarStatus(item._id, 'Rechazado por escuela', item.tipo) }}><i className="fa-solid fa-xmark"></i></CButton>
                                    </>
                                  :
                                  item.tipo == "casaAmigo" ?
                                    item.alumnoInvitaID.direccionesFijasRegreso[dayjs(item.fecha).get('day')].direccion == "papaRecoge" ?
                                      <>
                                        <CButton disabled={item.status == "Aceptado por escuela" ? true : false} color="success" onClick={() => { cambiarStatus(item._id, 'Aceptado por escuela', item.tipo) }}><i className="fa-solid fa-check"></i></CButton>
                                        <CButton style={{ marginLeft: "5px" }} disabled={item.status == "Rechazado por escuela" ? true : false} color="danger" onClick={() => { cambiarStatus(item._id, 'Rechazado por escuela', item.tipo) }}><i className="fa-solid fa-xmark"></i></CButton>
                                      </>
                                      :
                                      ruta ?
                                        <>
                                          <CButton disabled={false} color="success" onClick={() => { cambiarStatus(item._id, 'Aceptado por escuela', item.tipo, i) }}><i className="fa-solid fa-check"></i></CButton>
                                          <CButton style={{ marginLeft: "5px" }} disabled={item.status == "Rechazado por escuela" ? true : false} color="danger" onClick={() => { cambiarStatus(item._id, 'Rechazado por escuela', item.tipo) }}><i className="fa-solid fa-xmark"></i></CButton>
                                        </>
                                        :
                                        <>
                                          <CButton disabled={true} color="success" onClick={() => { cambiarStatus(item._id, 'Aceptado por escuela', item.tipo, i) }}><i className="fa-solid fa-check"></i></CButton>
                                          <CButton style={{ marginLeft: "5px" }} disabled={item.status == "Rechazado por escuela" ? true : item.status == "En espera de escuela" || item.status == "Aceptado por escuela" ? false : true} color="danger" onClick={() => { cambiarStatus(item._id, 'Rechazado por escuela', item.tipo) }}><i className="fa-solid fa-xmark"></i></CButton>
                                        </>
                                    : null
                          }
                        </CTableDataCell>
                      </>
                    )
                  }}
                />
              </>
            : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
        }

        <br />

      </div >
    </>
  )
}

export default Permisos
