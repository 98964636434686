import React from 'react'
import { CButton } from '@coreui/react';
import { useHistory } from "react-router-dom";
import dayjs from 'dayjs'
import './ImprimirLista.css'
import CIcon from '@coreui/icons-react'
import { cilArrowLeft } from '@coreui/icons';

function Imprimir() {
    const history = useHistory();
    const query = new URLSearchParams(location.search)
    let data = query.get('tipo') == 'papaRecoge' ? history.location.state.data.sort((a, b) => a.alumnoInvitadoID.degree > b.alumnoInvitadoID.degree ? 1 : -1) : history.location.state.data.sort((a, b) => a.alumnoInvitadoID.degree > b.alumnoInvitadoID.degree ? 1 : -1).filter(item => item.deRegreso == true)

    return (
        <>
            <div className='botonImprimir sticky noprint'>
                <CButton onClick={() => history.goBack()}><CIcon icon={cilArrowLeft} /> Atras</CButton>
                <CButton onClick={() => window.print()}>Imprimir</CButton>
            </div>

            <br />

            {
                data.length == 0 ?
                    <center>
                        <br />
                        <h1>No hay nada para imprimir</h1>
                    </center>

                    :

                    <>
                        <div className='center'>
                            <h4>{`${query.get('tipo') == 'cambioFijo' ? "Cambios fijos" : query.get('tipo') == 'permisos' ? "Cambios temporales" : "Pasan por ellos"} de ${query.get('filtro')} del dia ${dayjs(query.get('fecha')).format('DD-MM-YYYY')}`}</h4>
                        </div>

                        {

                            <table className="excel-style-table">
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Grado</th>
                                        <th>Direccion</th>
                                        <th>Ruta</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        data.map((item, index) => (
                                            <tr className='print' key={index}>
                                                <td>{item.alumnoInvitadoID.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</td>
                                                <td>{item.alumnoInvitadoID.degree.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</td>
                                                {
                                                    query.get('tipo') == 'papaRecoge' ?
                                                        <>
                                                            <td>{item.alumnoInvitadoID.sexo == "Hombre" ? "Pasan por el" : "Pasan por ella"}</td>
                                                            <td>---</td>
                                                        </>
                                                        : query.get('tipo') == 'permisos' || query.get('tipo') == 'cambioFijo' ?
                                                            <>
                                                                <td>{!item.direccionDestino ? `Se va a casa de: ${item.alumnoInvitaNombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}` : item.direccionDestino.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()).split(',').slice(0, 2).join(',')}</td>
                                                                <td>{item.idRutaDestino.numero} {item.idRutaDestino.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</td>
                                                            </>
                                                            :
                                                            null
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>

                            </table>
                        }
                    </>
            }
        </>
    )
}

export default Imprimir