import React, { useState, useContext } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import CIcon from '@coreui/icons-react'
import { CButton, CCard, CCardBody, CCardGroup, CCol, CContainer, CForm, CFormInput, CInputGroup, CInputGroupText, CRow, } from '@coreui/react'
import { cilLockLocked, cilUser } from '@coreui/icons'
// import { useNavigate } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import ReactLoading from 'react-loading'
import './Login.css';
import Logo from '../../assets/logo.jpg'

const Login = () => {
  const history = useHistory();
  let [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { loginUser } = useContext(AuthContext);
  const LOGIN_URL = `${process.env.REACT_APP_BackURL}/login`;
  // const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true)

    try {
      const res = await axios.post(LOGIN_URL, { email, password, });
      loginUser(res.data.token);

      Swal.fire({
        icon: "success",
        title: "Bienvenido",
        timer: 2000,
        timerProgressBar: true,
        allowEscapeKey: true,
      })
        .then(() => { /*navigate("/dashboard")*/history.push('/dashboard'); setLoading(false) });
    }

    catch (error) {
      console.log(error)
      let message = error.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Error en iniciar sesion",
        text: message,
        allowEscapeKey: true,
      })
        .then(() => { setLoading(false) });
    }
  };

  function handleForgot() {
    Swal.fire({ icon: "question", iconColor: 'red', title: 'Reestablecer contraseña', text: 'Recibirás un corréo con instrucciones para reestablecer tu contraseña.', input: 'email', allowEscapeKey: true, showCancelButton: true, cancelButtonText: 'Cancelar', cancelButtonColor: 'red', confirmButtonText: 'Enviar', confirmButtonColor: 'green', inputPlaceholder: 'Email' })
      .then((e) => {
        if (e.isConfirmed) {
          axios.post(`${process.env.REACT_APP_BackURL}/forgotPassword`, { mail: e.value })
            .then(() => { Swal.fire('Correo enviado con exito!') })
            .catch((err) => { Swal.fire('Ocurrio un error, intenta de nuevo por favor.') })
        }
      })
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>

            <center>
              <img className="img-fluid logo" src={Logo} alt="" />
            </center>

            <br /><br />

            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>Iniciar sesión</h1>
                    <p className="text-medium-emphasis">Ingresa correo electronico y contraseña</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput onChange={(e) => setEmail(e.target.value)} placeholder="Correo electrónico" />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Contraseña" />
                    </CInputGroup>
                    <CRow>

                      {
                        !loading ?
                          <>
                            <CCol lg={6}>
                              <br />
                              <CButton type="submit" color="success" className="px-4" onClick={() => handleSubmit()} >
                                Inicia Sesión
                              </CButton>
                            </CCol>
                          </>
                          : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
                      }

                      <CCol lg={6} >
                        <br />
                        {/* <CButton style={{ minWidth: '200px' }} color="info" className="px-0" onClick={() => { handleForgot() }}>¿Olvidaste tu contraseña?</CButton> */}
                      </CCol>

                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
