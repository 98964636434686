import React, { useState, useLayoutEffect } from 'react'
import { CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead, CButton } from '@coreui/react'
import axios from 'axios'
import ReactLoading from 'react-loading';
import './DiasHebreo.css'
import { TableVirtuoso } from 'react-virtuoso';
import dayjs from 'dayjs';
import Swal from "sweetalert2";

function DiasHebreo() {
  let [loading, setLoading] = useState(true)
  let [datos, setDatos] = useState([])
  let [id, setId] = useState()
  let [force, setForce] = useState(0)

  useLayoutEffect(() => {
    axios.get(`${process.env.REACT_APP_BackURL}/getAllDiasHebreo`)
      .then((response) => { setDatos(response.data.data[0].dias); setId(response.data.data[0]._id); setLoading(false) })
      .catch((err) => { console.log(err) })
  }, [])

  return (
    <>

      <center>
        <h3 className="margin">Dias solo hebreo</h3>
        <h5>{datos.length} dias registrados</h5>
      </center>

      <center>
        <span>Solo selecciona el dia para agregarlo</span>

        <br />

        <input type="date" onChange={(e) => {

          let temp = datos

          if (!temp.includes(e.target.value)) {

            temp.push(e.target.value)

            axios.patch(`${process.env.REACT_APP_BackURL}/updateDiasHebreo?id=${id}`, { dias: temp, })
              .then((data) => { setForce(force + 1) })
              .catch((err) => { Swal.fire({ icon: "error", title: "Error", text: "No se pudieron guardar los cambios", }); });
          }

        }} />

        <br /><br />

      </center>

      {
        !loading ?
          datos.length == 0 ?
            <center><h1>No hay registros</h1></center>
            :
            <>
              <TableVirtuoso
                style={{ height: "55vh", width: '100%' }}
                data={datos}
                components={{
                  Table: ({ style, ...props }) => <CTable {...props} style={{ backgroundColor: "rgb(82,93,113)" }} color="dark" hover />,
                  TableHead: CTableHead,
                  TableRow: CTableRow,
                  TableBody: CTableBody
                }}

                fixedHeaderContent={() => (
                  <CTableRow color='info'>
                    <CTableHeaderCell className='cells'>Fecha</CTableHeaderCell>
                    <CTableHeaderCell className='cells'>Eliminar</CTableHeaderCell>
                  </CTableRow>
                )}

                itemContent={(index, item) => {
                  return (
                    <>
                      <CTableDataCell className='cells'>{dayjs(item).format('DD/MM/YYYY')}</CTableDataCell>
                      <CTableDataCell className='cells'><CButton color='danger' onClick={() => {

                        let temp = datos

                        temp = temp.filter((e) => e != item)

                        setDatos(temp)

                        axios.patch(`${process.env.REACT_APP_BackURL}/updateDiasHebreo?id=${id}`, { dias: temp, })
                          .then((data) => { setForce(force + 1) })
                          .catch((err) => { Swal.fire({ icon: "error", title: "Error", text: "No se pudieron guardar los cambios", }) })

                      }}>Eliminar</CButton></CTableDataCell>
                    </>
                  )
                }}
              />
            </>
          : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
      }

      <br />

    </>
  )
}

export default DiasHebreo
