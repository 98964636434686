import React, { useState, useLayoutEffect } from 'react'
import { CFormInput, CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead } from '@coreui/react'
import axios from 'axios'
import ReactLoading from 'react-loading';
import './Prefectas.css'
import { TableVirtuoso } from 'react-virtuoso';

function Prefectas() {
  let [loading, setLoading] = useState(true)
  let [prefectasOG, setPrefectasOG] = useState([])
  let [prefectas, setPrefectas] = useState([])

  const excludeColumns = ["_id", "is_active", "createdAt", "updatedAt"]; // excluye datos del arreglo donde se buscara

  function getPermisos() {
    axios.get(`${process.env.REACT_APP_BackURL}/getAllPrefectas`)
      .then((response) => { setPrefectas(response.data.data); setPrefectasOG(response.data.data); setLoading(false) })
      .catch((err) => { console.log(err) })
  }

  // handle change event of search input
  const handleChange = (value) => {
    filterData(value)
  };

  // // filter records by search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setPrefectas(prefectasOG);
    else {
      const filteredData = prefectasOG.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setPrefectas(filteredData);
    }
  };

  useLayoutEffect(() => {
    getPermisos()
  }, [])

  return (
    <>
      <div className='centro'>

        <center>
          <CFormInput style={{ "width": "30%" }} type="text" id="floatingInput" label="Buscador" placeholder='Buscar' onChange={((e) => { handleChange(e.target.value) })} />
        </center>

        <br />

        <div className='botones'>

          <h5>{prefectas.length} prefectas registradas</h5>

        </div>

        <br />

        {
          !loading ?
            prefectas.length == 0 ?
              <center><h1>No hay prefectas registradas</h1></center>
              :
              <>
                <TableVirtuoso
                  style={{ height: "55vh", width: '100%' }}
                  data={prefectas}
                  components={{
                    Table: ({ style, ...props }) => <CTable {...props} style={{ backgroundColor: "rgb(82,93,113)" }} color="dark" hover />,
                    TableHead: CTableHead,
                    TableRow: CTableRow,
                    TableBody: CTableBody
                  }}

                  fixedHeaderContent={() => (
                    <CTableRow color='info'>
                      <CTableHeaderCell className='cells'>Nombre</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Numero</CTableHeaderCell>
                      <CTableHeaderCell className='cellsGrande'>Mail</CTableHeaderCell>
                    </CTableRow>
                  )}

                  itemContent={(index, item) => {
                    return (
                      <>
                        <CTableDataCell className='cells'>{item.collaborator_name.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</CTableDataCell>
                        <CTableDataCell className='cells'>{item.collaborator_number}</CTableDataCell>
                        <CTableDataCell className='cellsGrande'>{item.institutional_mail}</CTableDataCell>
                      </>
                    )
                  }}
                />
              </>
            : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
        }

        <br />

      </div>
    </>
  )
}

export default Prefectas
